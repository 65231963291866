<app-loading *ngIf="loading"></app-loading>
<div *ngIf="!loading" class="text-center" style="margin-top: 20px">
    <h2>Management Console</h2>
    <div>
        <a [routerLink]="['students']" class="btn btn-primary icon">
            <i class="fa fa-graduation-cap"></i>
            <br/>
            Students
        </a>
        <a [routerLink]="['tags']" class="btn btn-primary icon">
            <i class="fa fa-tag"></i>
            <br/>
            Tags
        </a>
        <a [routerLink]="['apps']" class="btn btn-primary icon">
            <i class="fa fa-mobile" aria-hidden="true"></i>
            <br/>
            Manage mobile apps
        </a>
        <a [routerLink]="['templates']" class="btn btn-primary icon" *ngIf="license?.features.manageStudentTemplates || license?.features.manageAppTemplates">
            <i class="fa fa-tags" aria-hidden="true"></i>
            <br/>
            Manage templates <span class="font-style:italic">(beta)</span>
        </a>
        <a [routerLink]="['program/report']"class="btn btn-primary icon">
            <i class="fa fa-area-chart" aria-hidden="true"></i>
            <br/>
            Program Report <span class="font-style:italic">(beta)</span>
        </a>
        <a [routerLink]="['abc']"class="btn btn-primary icon" *ngIf="license?.features.abc">
            <i class="fa fa-puzzle-piece" aria-hidden="true"></i>
            <br/>
            Manage ABC <span class="font-style:italic">(beta)</span>
        </a>
        <a [routerLink]="['download']"class="btn btn-primary icon">
            <i class="fa fa-download" aria-hidden="true"></i>
            <br/>
            Download
        </a>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="offset-2 col-3">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>License Expiration</td>
                            <td class="table-data">{{license?.expiration}}</td>
                        </tr>
                        <tr>
                            <td>Dedicated Licenses</td>
                            <td class="table-data">{{license?.singleCount}}</td>
                        </tr>
                        <tr>
                            <td>Flexible Licenses</td>
                            <td class="table-data">{{license?.multiCount}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="offset-1 col-3">
                <table class="table">
                    <thead>
                        <th></th>
                        <th>Used</th>
                        <th>Remaining</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Dedicated License</td>
                            <td>{{dedicatedLicenseCount}}</td>
                            <td>{{license?.singleCount - dedicatedLicenseCount}}</td>
                        </tr>
                        <tr>
                            <td>Flex License</td>
                            <td>{{flexLicenseCount}}</td>
                            <td>N/A</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>