<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>Tag Management</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label>Display Tag Names</label>
            <app-mtt-tags [(tagField)]="displayTags" [autoSort]="false" typeText="tag name" ></app-mtt-tags>
        </div>
        <div class="col-12 hint">
            This is the list of tags and their order which will be displayed in the student roster.
        </div>
        <div class="col-12">
            <button class="btn btn-primary" (click)="saveDisplayTags()"><i class="fa fa-floppy-o" *ngIf="!displayTagsSaving"></i><i class="fa fa-circle-o-notch fa-spin" *ngIf="displayTagsSaving"></i> Save</button>
        </div>
    </div>
    <div class="row">
        <div class="col-12 accordion" id="tagAccordion">
            <h2>Tag Explorer</h2>
            <div class="card" *ngFor="let tag of tags">
                <div class="card-header" [attr.id]="'th' + tag.id">
                    <a class="control-link" style="right: 5px;top: 10px;" *ngIf="processing"><i class="fa fa-circle-o-notch fa-spin"></i></a>
                    <a class="control-link" style="right: 5px;top: 10px;" (click)="tag.delete()" *ngIf="!processing">
                        <i class="fa fa-trash" *ngIf="!tag.deleting"></i>
                        <i class="fa fa-circle-o-notch fa-spin" *ngIf="tag.deleting"></i>
                    </a>
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#t' + tag.id" [attr.aria-expanded]="'false'" [attr.aria-controls]="'t' + tag.id">
                        Tag Name: {{tag.name}} (Values: {{tag.values.length}}, Students: {{tag.studentCount}})
                    </button>
                </div>
                <div [attr.id]="'t' + tag.id" class="collapse" [attr.aria-labelledby]="'th' + tag.id" data-parent="#tagAccordion">
                    <div class="card-body settings-accordian-body">
                        <div class="accordion" [attr.id]="'valAccordion' + tag.id">
                            <div class="card" *ngFor="let val of tag.values">
                                <div class="card-header" [attr.id]="'tvh' + val.id">
                                    <a class="control-link" style="right: 5px;top: 10px;" (click)="val.delete()">
                                        <i class="fa fa-trash" *ngIf="!val.deleting"></i>
                                        <i class="fa fa-circle-o-notch fa-spin" *ngIf="val.deleting"></i>
                                    </a>
                                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#tv' + val.id" [attr.aria-expanded]="'false'" [attr.aria-controls]="'tv' + val.id">
                                        Tag Value: {{val.value}} (Students: {{val.studentCount}})
                                    </button>
                                </div>
                                <div [attr.id]="'tv' + val.id" class="collapse" [attr.aria-labelledby]="'tvh' + val.id" [attr.data-parent]="'#valAccordion' + tag.id">
                                    <div class="card-body settings-accordian-body">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let student of val.students">
                                                    <td>{{student.details.firstName}}</td>
                                                    <td>{{student.details.lastName}}</td>
                                                    <td><a class="control-link" (click)="removeStudentFromTag(student, tag, val)" *ngIf="!student.saving"><i class="fa fa-minus"></i></a></td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>