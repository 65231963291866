<div class="modal-dialog">
  <div class="modal-content background-customizable modal-content-mobile visible-xs visible-sm">
    <div class="banner-customizable">
      <img class="logo-customizable"
        src="https://dpp0gtxikpq3y.cloudfront.net/us-west-2_Gw7zVNGLF/ALL/20190119041210/assets/images/image.jpg">
    </div>
    <div class="modal-body" *ngIf="isForgotPassword">
      <div class="row">
        <div class="panel col-md-12 col-lg-12">
          <div>
            <h1>Forgot your password?</h1>
            <span class="textDescription-customizable ">Enter your Email below and we will send a message to reset your
              password</span>
            <br />
            <div *ngIf="(error)" class="errorMessage">
                <p id="loginErrorMessage" class="errorMessage-customizable">{{ error }}</p>
            </div>
            <form class="cognito-asf" [formGroup]="forgotPasswordForm" (ngSubmit)="OnSubmit()">
              <div><input formControlName="email" class="form-control inputField-customizable" placeholder="Email"></div>
              <div *ngIf="forgotPasswordForm.controls['email'].invalid && (forgotPasswordForm.controls['email'].dirty || forgotPasswordForm.controls['email'].touched)">
                  <div *ngIf="forgotPasswordForm.controls['email'].errors.required" class="checkPassword-length passwordCheck-notValid-customizable">
                      Email is required.
                  </div>
                  <div *ngIf="forgotPasswordForm.controls['email'].errors.email" class="checkPassword-length passwordCheck-notValid-customizable">
                      Email must be a valid email address.
                  </div>
              </div>
              <input name="signInSubmitButton" [disabled]="!forgotPasswordForm.valid" type="Submit" value="Reset my password"
                class="btn btn-primary submitButton-customizable">
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-body" *ngIf="isResetPassword">
      <div class="row">
        <div class="panel col-md-12 col-lg-12">
          <div>
            <span class="textDescription-customizable ">We have sent a password reset code by email to {{ destination }}. Enter it below to reset your password.</span>
            <br />
            <div *ngIf="(resetPasswordError)" class="errorMessage">
              <p id="loginErrorMessage" class="errorMessage-customizable">{{ resetPasswordError }}</p>
            </div>
            <form class="cognito-asf" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword(this.forgotPasswordForm.value.email)" novalidate>
              <div class="form-group">
                <label class="label-customizable">Code</label>
                <input formControlName="verificationCode" type="password" class="form-control inputField-customizable">
                <div *ngIf="resetPasswordForm.controls['verificationCode'].invalid && (resetPasswordForm.controls['verificationCode'].dirty || resetPasswordForm.controls['verificationCode'].touched)">
                  <div *ngIf="resetPasswordForm.controls['verificationCode'].errors.required" class="checkPassword-length passwordCheck-notValid-customizable">
                    Code is required.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="label-customizable">New Password</label>
                <input formControlName="password" type="password" class="form-control inputField-customizable">
                <div *ngIf="resetPasswordForm.controls['password'].invalid && (resetPasswordForm.controls['password'].dirty || resetPasswordForm.controls['password'].touched)">
                    <div *ngIf="resetPasswordForm.controls['password'].errors.required" class="checkPassword-length passwordCheck-notValid-customizable">
                    Password is required.
                    </div>
                </div>
              </div>
              <div class="form-group">
                <label class="label-customizable">Enter New Password Again</label>
                <input formControlName="confirmPassword" type="password" class="form-control inputField-customizable">
                <div *ngIf="resetPasswordForm.controls['confirmPassword'].invalid && (resetPasswordForm.controls['confirmPassword'].dirty || resetPasswordForm.controls['confirmPassword'].touched)">
                    <div *ngIf="resetPasswordForm.controls['confirmPassword'].errors.required" class="checkPassword-length passwordCheck-notValid-customizable">
                    Confirm Password is required.
                    </div>
                </div>
                <div *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.notEquivalent">
                  <div class="checkPassword-length passwordCheck-notValid-customizable">
                    <span class="checkPasswordText-length">Confirm Password did not match</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <input name="signInSubmitButton" [disabled]="!resetPasswordForm.valid" type="Submit" value="Change Password"
                class="btn btn-primary submitButton-customizable">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  