<h1>Student Manager</h1>
<div class="container-fluid">
    <div class="row">
        <div class="col-12" *ngIf="flexChart">
            <canvas id="dotChart" class="limited-chart" baseChart width="400" height="100" 
                [datasets]="flexChart.data.datasets" 
                [labels]="flexChart.data.labels" 
                [options]="flexChart.options" 
                [type]="flexChart.type"
                [legend]="true"></canvas>
        </div>
        <div class="col-12">
            <label>Import Students</label>
            <br/>
            <input #importFileElement class="btn" type="file" [(ngModel)]="importFile" observeFiles/>
            <span *ngIf="loading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
        </div>
        <div class="col-12">
            <label><input [(ngModel)]="showAdminButton" type="checkbox"/> Add Admins To Students</label>
        </div>
        <div class="col-12">
            <table class="table">
                <thead>
                    <th>Student</th>
                    <th>Nickname</th>
                    <th>Dedicated ({{remainingDedicated}})</th>
                    <th *ngIf="license?.multiCount > 0">Flexible</th>
                    <th>None</th>
                    <th>Archive</th>
                    <th>Transfer</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr *ngFor="let student of students">
                        <td><span *ngIf="student.deleted">Removed: </span>{{student.details.firstName}} {{student.details.lastName}}</td>
                        <td>{{student.details.subtext}}</td>
                        <td><input *ngIf="!student.deleted" [name]="student.studentId" [id]="student.studentId + '-ded'" type="radio" [checked]="student.licenseDetails?.fullYear" (click)="student.applyLicense('Single')" [disabled]="remainingDedicated &lt;= 0" /></td>
                        <td *ngIf="license?.multiCount > 0"><input *ngIf="!student.deleted" [name]="student.studentId" [id]="student.studentId + '-flex'" type="radio" [checked]="student.licenseDetails?.flexible" (click)="student.applyLicense('Multi')" /></td>
                        <td><input *ngIf="!student.deleted" [name]="student.studentId" [id]="student.studentId + '-none'" type="radio" [checked]="!student.licenseDetails?.fullYear && !student.licenseDetails?.flexible && !student.licenseDetails?.transferable && !student.archived" (click)="student.applyLicense('No License')"/></td>
                        <td><input *ngIf="!student.deleted" [name]="student.studentId" [id]="student.studentId + '-flex'" type="radio" [checked]="student.archived" (click)="student.applyLicense('Archive')" /></td>
                        <td><input *ngIf="!student.deleted" [name]="student.studentId" [id]="student.studentId + '-transfer'" type="radio" [checked]="student.licenseDetails?.transferable" (click)="student.applyLicense('Other')" /></td>
                        <td *ngIf="!student.saving">
                            <button *ngIf="!showAdminButton" class="btn btn-primary" (click)="remove(student)"><i class="fa fa-trash"></i> Remove</button>
                            <button *ngIf="showAdminButton" class="btn btn-primary" (click)="addAdmin(student)"><i class="fa fa-plus"></i> Add Admin</button>
                        </td>
                        <td *ngIf="student.saving"><i class="fa fa-circle-o-notch fa-spin"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>