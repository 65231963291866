<div class="view-area">
    <div class="container" *ngIf="!loading">
        <div class="row" *ngIf="!editPassword">
            <div class="ml-auto mr-auto col-xl-8 col-lg-8 col-xs-12 col-md-12">
                <h4>Your Profile</h4>
                <hr>
            </div>
            <div class="ml-auto mr-auto col-xl-8 col-lg-8 col-xs-12 col-md-12">
                <div class="form-group row">
                    <label class="col-4">First Name</label>
                    <div class="col-8">
                        <p *ngIf="!edit">{{newData.firstName}}</p>
                        <input class="form-control" (change)="validateProfile(newData)" *ngIf="edit" maxlength="20" [(ngModel)]="newData.firstName" />
                        <div class="validation-error-text" *ngIf="edit && error.firstName">{{error.firstName}}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">Last Name</label>
                    <div class="col-8">
                        <p *ngIf="!edit">{{newData.lastName}}</p>
                        <input class="form-control" (change)="validateProfile(newData)" *ngIf="edit" maxlength="20" [(ngModel)]="newData.lastName" />
                        <div class="validation-error-text" *ngIf="edit && error.lastName">{{error.lastName}}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">Display Name</label>
                    <div class="col-8">
                        <p *ngIf="!edit">{{newData.name}}</p>
                        <input class="form-control" (change)="validateProfile(newData)" *ngIf="edit" maxlength="20" [(ngModel)]="newData.name" />
                        <div class="validation-error-text" *ngIf="edit && error.name">{{error.name}}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">State</label>
                    <div class="col-8">
                        <p *ngIf="!edit">{{stateHashmap[newData.state]}}</p>
                        <select *ngIf="edit" class="form-control" [(ngModel)]="newData.state">
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                      </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">Zip Code</label>
                    <div class="col-8">
                        <p *ngIf="!edit">{{newData.zip}}</p>
                        <input class="form-control" (change)="validateProfile(newData)" *ngIf="edit" [(ngModel)]="newData.zip" />
                        <div class="validation-error-text" *ngIf="edit && error.zip">{{error.zip}}</div>
                    </div>
                </div>
                <div class="row">
                    &nbsp;
                </div>
                <div class="form-group row">
                    <div *ngIf="!editPassword" class="col-6 mr-auto">
                        <button class="btn btn-primary" (click)="editPassword = true">Change Password</button>
                    </div>
                    <div class="col-6 ml-auto" *ngIf="edit">
                        <button class="btn btn-primary float-right" (click)="cancelEdit()">Cancel</button>
                        <button class="btn btn-primary float-right" (click)="saveProfile()">Save</button>
                    </div>
                    <div class="col-6 ml-auto" *ngIf="!edit && !editPassword">
                        <button class="btn btn-primary float-right" (click)="editProfile()">Edit</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="editPassword">
            <div class="ml-auto mr-auto col-xl-8 col-lg-8 col-xs-12 col-md-12">
                <h4>Change Password</h4>
                <h6>It's a good idea to use a strong password</h6>
                <hr>
            </div>
            <div class="ml-auto mr-auto col-xl-8 col-lg-8 col-xs-12 col-md-12">
                <div class="form-group row">
                    <label class="col-4">Current Password</label>
                    <div class="col-6">
                        <input id="oldPassword" autocomplete="off" type="password" class="form-control" [(ngModel)]="oldPassword" />
                    </div>
                    <div class="validation-error-text col-2" *ngIf="required(oldPassword)">
                        Required
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">New Password</label>
                    <div class="col-6">
                        <input id="newPassword" autocomplete="off" type="password" class="form-control" [(ngModel)]="newPassword" />
                    </div>
                    <div class="validation-error-text col-2" *ngIf="required(newPassword)">
                        Required
                    </div>
                    <div class="validation-error-text col-2" *ngIf="!required(newPassword)">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">Confirm Password</label>
                    <div class="col-6">
                        <input id="confirmPassword" autocomplete="off" type="password" class="form-control" [(ngModel)]="confirmPassword" />
                    </div>
                    <div class="validation-error-text col-2" *ngIf="required(confirmPassword)">
                        Required
                    </div>
                </div>
                <div class="form-group row">
                    <div class="validation-error-text col-6 mr-auto" *ngIf="error.password">
                        {{error.password}}
                    </div>
                    <div class="col-6 ml-auto">
                        <button class="btn btn-primary float-right" (click)="editPassword = false">Cancel</button>
                        <button class="btn btn-primary float-right" (click)="changePassword()">Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="loading"></app-loading>