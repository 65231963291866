<div class="container-fluid">
    <div class="row">
        <div class="col-12" *ngIf="!studentView">
            <label>Name</label>
            <input class="form-control" [(ngModel)]="item.name" />
        </div>
        <div class="col-12" *ngIf="!studentView">
            <label>Filter Students By Tags:</label>
            <app-mtt-tags [(tagField)]="item.tags"></app-mtt-tags>
        </div>
        <div class="col-6">
            <label>Antecedents</label>
            <textarea class="form-control" rows="6" [(ngModel)]="antecedents" [readonly]="readOnly"></textarea>
            <div class="hint">
                add one antecedent per line
            </div>
        </div>
        <div class="col-6">
            <label>Consequence</label>
            <textarea class="form-control" rows="6" [(ngModel)]="consequences" [readonly]="readOnly"></textarea>
            <div class="hint">
                add one consequence per line
            </div>
        </div>
        <div class="col-12" *ngIf="!readOnly">
            <button class="btn btn-primary" (click)="save()">
                <i class="fa" [class.fa-floppy-o]="!isSaving" [class.fa-circle-o-notch]="isSaving" [class.fa-spin]="isSaving"></i> Save
            </button>
            <button class="btn btn-secondary" (click)="cancel()"><i class="fa fa-times"></i> Cancel</button>
            <button class="btn btn-danger" (click)="delete()">
                <i class="fa fa-trash" [class.fa-trash]="!isDeleting" [class.fa-circle-o-notch]="isDeleting" [class.fa-spin]="isDeleting"></i> Delete
            </button>
        </div>
    </div>
</div>