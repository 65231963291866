<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <div class="btn-group">
                <button class="btn" 
                        [class.btn-primary]="selectionType == 'week'" 
                        [class.btn-secondary]="selectionType != 'week'" 
                        (click)="setSelectionType(ReportDuration.week)">Week</button>
                <button class="btn" 
                        [class.btn-primary]="selectionType == 'month'" 
                        [class.btn-secondary]="selectionType != 'month'" 
                        (click)="setSelectionType(ReportDuration.month)">Month</button>
                <button class="btn"
                        [class.btn-primary]="selectionType == 'year'" 
                        [class.btn-secondary]="selectionType != 'year'" 
                        (click)="setSelectionType(ReportDuration.year)">Year</button>
                <button class="btn" 
                        [class.btn-primary]="selectionType == 'range'" 
                        [class.btn-secondary]="selectionType != 'range'" 
                        (click)="setSelectionType(ReportDuration.range)">Range</button>
            </div>
            <div>
                <ngb-datepicker *ngIf="selectionType == 'week'" #dp [startDate]="fromDate" [ngModel]="model" (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t" outsideDays="show" firstDayOfWeek="7">
                </ngb-datepicker>
            </div>

            <div style="text-align: left" *ngIf="selectionType == 'range'">
                <label>Start:</label>
                <input type="date" class="form-control" [(ngModel)]="rangeStart" />
                <label>End:</label>
                <input type="date" class="form-control" [(ngModel)]="rangeEnd" />
                <button class="btn btn-primary" (click)="applyRange()">Apply</button>
            </div>

            <div *ngIf="selectionType == 'month'">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <a class="link" (click)="yearAdd(-1)"><i class="fa fa-chevron-left" aria-hidden="true"></i></a> {{startDate.year()}} <a class="link" (click)="yearAdd(1)"><i class="fa fa-chevron-right" aria-hidden="true"></i></a>
                        </div>
                        <div class="col-4" *ngFor="let month of months">
                            <button (click)="selectMonth(month)" class="btn" [class.btn-primary]="isMonthSelected(month)" [class.btn-secondary]="!isMonthSelected(month)">{{month}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectionType == 'year'"> 
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-4" *ngFor="let year of years">
                            <button (click)="selectYear(year)" class="btn btn-primary" [class.btn-primary]="isYearSelected(year)" [class.btn-secondary]="!isYearSelected(year)">{{year}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <ng-template #t let-date let-focused="focused">
                <span class="custom-day" [class.focused]="focused" [class.outsideMonth]="isOutsideMonth(date)" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
            </ng-template>
        </div>
    </div>
</div>