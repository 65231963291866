import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Provider } from '@angular/core';
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
      ngModule: Type<T>;
      providers?: (Provider | EnvironmentProviders)[];
  }
}

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SettingsComponent } from './components/student/settings/settings.component';
import { CreateComponent } from './components/student/create/create.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { SetupComponent } from './components/user/setup/setup.component';
import { ForgotpasswordComponent } from './components/user/forgotpassword/forgotpassword.component';
import { Dashboard2Component } from './components/student/dashboard2/dashboard2.component';
import { SettingsComponent as DashboardSettings } from './components/student/dashboard2/settings/settings.component';
import { ManageScheduleComponent } from './components/student/settings/manage-schedule/manage-schedule.component';
import { MytaptrackWebCommonModule } from './lib/web-common.module';
import { AuthClientService, ErrorHandlingService } from './lib';
import { BehaviorsComponent } from './components/student/settings/behaviors/behaviors.component';
import { DevicesComponent } from './components/student/settings/devices/devices.component';
import { TeamComponent } from './components/student/settings/team/team.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MilestonesComponent } from './components/student/settings/milestones/milestones.component';
import { NgxPopper } from 'angular-popper';
import { TrackingComponent } from './components/student/tracking/tracking.component';
import { LicensesComponent } from './components/mtt-admin/licenses/licenses.component';
import { DashboardMobileComponent } from './components/student/dashboard-mobile/dashboard-mobile.component';
import {NgxPrintModule} from 'ngx-print';
import { DownloadComponent } from './components/student/download/download.component';
import { ReportComponent } from './components/student/report/report.component';
import { NgxEditorModule } from 'ngx-editor';
import { ListComponent } from './components/student/list/list.component';
import { InviteComponent } from './components/student/list/invite/invite.component';
import { DeviceManagementComponent } from './components/manage/device-management/device-management.component';
import { ConsoleComponent } from './components/manage/console/console.component';
import { ResponseTrackingComponent } from './components/student/settings/response-tracking/response-tracking.component';
import { TemplatesComponent } from './components/manage/templates/templates.component';
import { ManageStudentComponent } from './components/manage/templates/student/student.component';
import { TemplateAppComponent } from './components/manage/templates/app/app.component';
import { PrintComponent } from './components/student/print/print.component';
import { ProgramReportComponent } from './components/manage/program-report/program-report.component';
import { ManageDownloadDataComponent } from './components/manage/download-data/download-data.component';
import { AbcManagerComponent } from './components/manage/abc-manager/abc-manager.component';
import { AbcCollectionComponent } from './components/manage/abc-manager/abc-collection/abc-collection.component';
import { AbcComponent } from './components/student/settings/abc/abc.component';
import { SubscriptionsComponent } from './components/student/settings/subscriptions/subscriptions.component';
import { ManageStudentsComponent } from './components/manage/students/students.component';
import { BehaviorComponent } from './components/student/settings/behaviors/behavior/behavior.component';
import { DocumentsComponent } from './components/student/settings/documents/documents.component';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { TagManagementComponent } from './components/manage/tag-management/tag-management.component';
import { CompareTrackingComponent } from './components/student/tracking/compare-tracking/compare-tracking.component';
import { IntervalPromptComponent } from './components/student/tracking/interval-prompt/interval-prompt.component';
import { ReportValueComponent } from './components/student/report/report-value/report-value.component';
import { environment } from '../environments/environment';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

AuthClientService.setEnvironment(environment);
``
const providers: Provider[] = [ CookieService ];
providers.push({provide: ErrorHandler, useClass: ErrorHandlingService },
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'} });

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SettingsComponent,
    CreateComponent,
    ProfileComponent,
    SetupComponent,
    ForgotpasswordComponent,
    Dashboard2Component,
    ManageScheduleComponent,
    // TimeInputComponent,
    BehaviorsComponent,
    DevicesComponent,
    TeamComponent,
    MilestonesComponent,
    TrackingComponent,
    LicensesComponent,
    DashboardMobileComponent,
    DownloadComponent,
    DashboardSettings,
    ReportComponent,
    ListComponent,
    InviteComponent,
    DeviceManagementComponent,
    ConsoleComponent,
    ResponseTrackingComponent,
    TemplatesComponent,
    ManageStudentComponent,
    TemplateAppComponent,
    // DateSelectionComponent,
    // EditDropdownComponent,
    PrintComponent,
    ProgramReportComponent,
    ManageDownloadDataComponent,
    AbcManagerComponent,
    AbcCollectionComponent,
    AbcComponent,
    SubscriptionsComponent,
    ManageStudentsComponent,
    BehaviorComponent,
    DocumentsComponent,
    TagManagementComponent,
    CompareTrackingComponent,
    IntervalPromptComponent,
    ReportValueComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgxPopper,
    NgxPrintModule,
    NgxEditorModule,
    AmplifyAuthenticatorModule,
    MytaptrackWebCommonModule
  ],
  exports: [
  ],
  providers,
  bootstrap: [AppComponent]
})
export class AppModule { }
