<div class="container">
    <div class="row">
        <div class="col-12">
            <h2>Download</h2>
        </div>
        <div class="col-12" *ngIf="false">
            <label>Filter by tags:</label>
            <app-mtt-tags [(tagField)]="tags"></app-mtt-tags>
            <div>Students: {{students.length}}</div>
        </div>
        <div class="col-6">
            <label>Start Date</label>
            <input class="form-control" type="date" [(ngModel)]="startDate"/>
        </div>
        <div class="col-6">
            <label>End Date</label>
            <input class="form-control" type="date" [(ngModel)]="endDate"/>
        </div>
        <div class="col-12">
            <button class="btn btn-primary" (click)="downloadReport()"><i class="fa fa-download"></i> Download</button>
        </div>
        <div *ngIf="completePercent > 0">
            {{completePercentDisplay}} %
        </div>
    </div>
</div>