import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { SettingsComponent } from './components/student/settings/settings.component';
import { CreateComponent } from './components/student/create/create.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { SetupComponent } from './components/user/setup/setup.component';
import { ForgotpasswordComponent } from './components/user/forgotpassword/forgotpassword.component';
import { Dashboard2Component } from './components/student/dashboard2/dashboard2.component';
import { Angulartics2Module } from 'angulartics2';
import { TrackingComponent } from './components/student/tracking/tracking.component';
import { LicensesComponent } from './components/mtt-admin/licenses/licenses.component';
import { DashboardMobileComponent } from './components/student/dashboard-mobile/dashboard-mobile.component';
import { DownloadComponent } from './components/student/download/download.component';
import { ReportComponent } from './components/student/report/report.component';
import { ConsoleComponent } from './components/manage/console/console.component';
import { DeviceManagementComponent } from './components/manage/device-management/device-management.component';
import { TemplatesComponent } from './components/manage/templates/templates.component';
import { PrintComponent } from './components/student/print/print.component';
import { ProgramReportComponent } from './components/manage/program-report/program-report.component';
import { ManageDownloadDataComponent } from './components/manage/download-data/download-data.component';
import { AbcManagerComponent } from './components/manage/abc-manager/abc-manager.component';
import { ManageStudentsComponent } from './components/manage/students/students.component';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { TagManagementComponent } from './components/manage/tag-management/tag-management.component';
import { CompareTrackingComponent } from './components/student/tracking/compare-tracking/compare-tracking.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: Dashboard2Component, canDeactivate: [(component: Dashboard2Component) => {
    if(!component.hasUnsavedChanges) {
      return true;
    }
    if(confirm("There are unsaved changed, do you wish to navigate away?")) {
      return true;
    }
    return false;
  }] },
  { path: 'mobile', pathMatch: 'full', component: DashboardMobileComponent },
  { path: 'dashboard', component: Dashboard2Component, canDeactivate: [(component: Dashboard2Component) => {
    if(!component.hasUnsavedChanges || confirm("There are unsaved changed, do you wish to navigate away?")) {
      return true;
    }
    return false;
  }] },
  { path: 'student/tracking', component: TrackingComponent },
  { path: 'student/tracking/compare', component: CompareTrackingComponent },
  { path: 'student/settings', component: SettingsComponent },
  { path: 'student/settings/:tabName', component: SettingsComponent },
  { path: 'student/create', component: CreateComponent },
  { path: 'student/download', component: DownloadComponent },
  { path: 'student/report', component: ReportComponent},
  { path: 'student/print', component: PrintComponent},
  { path: 'profile', component: ProfileComponent },
  { path: 'setup', component: SetupComponent },
  { path: 'manage', component: ConsoleComponent },
  { path: 'manage/apps', component: DeviceManagementComponent },
  { path: 'manage/templates', component: TemplatesComponent },
  { path: 'manage/templates/:tabName', component: TemplatesComponent },
  { path: 'manage/program/report', component: ProgramReportComponent},
  { path: 'manage/download', component: ManageDownloadDataComponent},
  { path: 'manage/abc', component: AbcManagerComponent },
  { path: 'manage/students', component: ManageStudentsComponent },
  { path: 'manage/tags', component: TagManagementComponent },
  { path: 'forgotPassword', component: ForgotpasswordComponent },
  { path: 'admin/licenses', component: LicensesComponent },
  { path: '**', component: Dashboard2Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' }),
    Angulartics2Module.forRoot()],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
