<div class="details" *ngIf="selected">
    <app-loading class="saving" *ngIf="loading" [absolute]="true" text="Saving"></app-loading>
    <div class="accordion" id="settingsAccordion">
        <div class="card">
            <div class="card-header" id="headingGeneralSettings">
                <h2 class="mb-0">
                    <button id="student-setting-behavior-general" class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#generalSettings" aria-expanded="true" aria-controls="generalSettings">
                        General Settings
                    </button>
                </h2>
            </div>
            <div id="generalSettings" class="collapse show" aria-labelledby="headingGeneralSettings" data-parent="#settingsAccordion">
                <div class="card-body">
                    <div class="form-group">
                        <label>Name</label>
                        <input id="student-setting-behavior-name" class="form-control" [(ngModel)]="selected.name" [readonly]="!administrator" />
                    </div>
                    <div class="form-group">
                        <label>Description</label>
                        <textarea id="student-setting-behavior-desc" class="form-control" [(ngModel)]="selected.desc" [readonly]="!administrator" placeholder="What does the behavior look like?"></textarea>
                    </div>
                    <div class="container-fuid">
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <input id="student-setting-behavior-isDuration" type="checkbox" [(ngModel)]="selected.isDuration" [disabled]="!administrator" />
                                    <label for="student-setting-behavior-isDuration">Duration</label>
                                </div>
                            </div>
                            <div class="col-9" *ngIf="selected.isDuration">
                                <div class="form-group">
                                    <input id="student-setting-behavior-daytime" type="checkbox" [(ngModel)]="selected.daytime" [disabled]="!administrator" />
                                    <label for="student-setting-behavior-daytime">Daytime Tracking</label>
                                </div>
                                <div class="warn" *ngIf="!selected.daytime">
                                    Un-checking this box will allow overnight tracking, but can be hard to identify if you miss stopping the timer
                                </div>
                            </div>
                            <div class="col-6" *ngIf="hasIntervalWCompare">
                                <label><input id="student-setting-behavior-baseline" type="checkbox" [(ngModel)]="selected.baseline" [disabled]="!administrator"/> Baseline Student</label>
                            </div>
                            <div class="col-6" *ngIf="hasAbc">
                                <label><input id="student-setting-behavior-abc" type="checkbox" [(ngModel)]="selected.trackAbc" [disabled]="!administrator"/> Track ABC</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" *ngIf="features.behaviorTargets">
            <div class="card-header" id="headingTargets">
                <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#targets" aria-expanded="true" aria-controls="targets">
                        Targets and Goals
                    </button>
                </h2>
            </div>
            <div id="targets" class="collapse" aria-labelledby="headingTargets" data-parent="#settingsAccordion">
                <div class="card-body settings-accordian-body">
                    <div class="hint">Optional targets add to the dashboard information on goals</div>
                    <div class="target" [class.target-bordered]="frequencyTarget.show">
                        <input id="FrequencyTarget" type="checkbox" [disabled]="!administrator" [(ngModel)]="frequencyTarget.show" [disabled]="!administrator"/><label for="FrequencyTarget">Set Frequency Target</label>
                        <div *ngIf="frequencyTarget.show">
                            <div class="objective-block" *ngFor="let category of targetCategories; let i = index">
                                <div *ngIf="i < targetCategories.length - 1">
                                    <label>{{category}}</label>
                                    <div class="input-group" *ngIf="frequencyTarget.getCategoryValue(category)">
                                        <input type="number" class="form-control" style="text-align:right" [disabled]="!administrator" [(ngModel)]="frequencyTarget.getCategoryValue(category).frequency" />
                                        <div class="input-group-append">
                                            <span class="input-group-text">Events</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="target-legend text-center" *ngIf="frequencyTarget.showLegend && reportingOn">
                                <div>
                                    <label>Report Legend</label>
                                </div>
                                <div class="item" *ngFor="let item of targetCategories; let i = index">
                                    <div>
                                        <app-report-value [faces]="[{ face: item }]" [snapshotConfig]="snapshotConfig" [dayOffset]="0" [allowSelection]="false"></app-report-value>
                                        <div>{{getLegendMeasurement(i, true)}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="target" *ngIf="selected.isDuration" [class.target-bordered]="durationTarget.show">
                        <input id="DurationTarget" type="checkbox" [disabled]="!administrator" [(ngModel)]="durationTarget.show" />
                        <label for="DurationTarget">Set Duration Target ([hh:]mm:ss)</label>
                        <div *ngIf="durationTarget && durationTarget.show">
                            <div class="objective-block" *ngFor="let category of targetCategories; let i = index">
                                <div *ngIf="i < targetCategories.length - 1">
                                    <label>{{category}}</label>
                                    <div class="input-group" *ngIf="durationTarget.getCategoryValue(category)">
                                        <input type="string" class="form-control" style="text-align:right" [disabled]="!administrator" [(ngModel)]="durationTarget.getCategoryValue(category).duration" />
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="!administrator">{{durationTarget.measurement}}</button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" (click)="durationTarget.measurement = 'Avg'">Avg</a>
                                                <a class="dropdown-item" (click)="durationTarget.measurement = 'Sum'">Sum</a>
                                                <a class="dropdown-item" (click)="durationTarget.measurement = 'Max'">Max</a>
                                                <a class="dropdown-item" (click)="durationTarget.measurement = 'Min'">Min</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="target-legend text-center" *ngIf="durationTarget.showLegend && reportingOn">
                                <div>
                                    <label>Report Legend ([hh:]mm:ss)</label>
                                </div>
                                <div class="item" *ngFor="let item of targetCategories; let i = index">
                                    <div>
                                        <app-report-value [faces]="[{ face: item }]" [snapshotConfig]="snapshotConfig" [dayOffset]="0" [allowSelection]="false"></app-report-value>
                                        <div>{{getLegendMeasurement(i, false)}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center" *ngIf="administrator && !onlyShowDelete">
        <button class="btn btn-primary" (click)="save()">Save</button>
        <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
        <button *ngIf="!selected.isArchived" class="btn btn-danger" (click)="archive()">Archive</button>
        <button *ngIf="selected.isArchived" class="btn btn-success" (click)="reactivate()">Reactivate</button>
    </div>
    <div class="text-cenrer" *ngIf="onlyShowDelete && selected && selected.id">
        <button class="btn btn-danger" (click)="selected.delete()">Delete</button>
    </div>
    <div class="status">
        {{status}}
    </div>
</div>