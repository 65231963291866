<div class="scroll-header">
    <h2>{{student?.displayName}} - Download Data</h2>
</div>
<div class="container">
    <div class="row">
        <div class="col-lg-4">
            <div class="date-group text-center">
                <div class="btn-group">
                    <button class="btn" (click)="reportType = 'Data'" [class.btn-primary]="reportType == 'Data'" [class.btn-secondary]="reportType != 'Data'">All Data</button>
                    <button class="btn" (click)="reportType = 'Frequency'" [class.btn-primary]="reportType == 'Frequency'" [class.btn-secondary]="reportType != 'Frequency'">Interval</button>
                </div>
                <div *ngIf="isWeek(selection)">
                    <app-date-selection
                        [startDate]="downloadDate"
                        (selected)="dateSelected($event)"></app-date-selection>
                </div>
            </div>
        </div>
        <div class="offset-lg-1 col-lg-5" *ngIf="reportType != 'Frequency'">
            <div>
                <div>
                    <h3>Format Options</h3>
                </div>
                <div class="form-group">
                    <label>Duration Measurement Unit:</label>
                    <select class="form-control" [(ngModel)]="durationUnits">
                        <option value="Seconds">Seconds</option>
                        <option value="Minutes">Minutes</option>
                        <option value="Hours">Hours</option>
                    </select>
                </div>
                <div>
                    <label><input type="checkbox" [(ngModel)]="onlyDurationStart" /> Only show the start of the duration.</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <h2>Download Configuration</h2>
            <label>Date Range</label>
            <div>
                {{downloadDate?.format('MM/DD/yyyy')}} - {{downloadEndDate?.format('MM/DD/yyyy')}}
            </div>
            <label>Unit</label>
            <div>{{durationUnits}}</div>
            <div class="text-center spacer">
                <button class="btn btn-primary" (click)="downloadReport()"><i class="fa fa-download" aria-hidden="true"></i> Download</button>
            </div>
            <div>
                <div *ngIf="completePercent &gt; -1" class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" [style.width]="completePercent + '%'">
                        {{completePercent}} %
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6" *ngIf="reportType == 'Frequency'">
            <div>
                <label>Interval</label>
            </div>
            <div class="btn-group text-center minute-selection">
                <button class="btn" (click)="setSelectedRange(1)" [class.btn-primary]="rangeSelected(1)" [class.btn-secondary]="!rangeSelected(1)">1 minute</button>
                <button class="btn" (click)="setSelectedRange(5)" [class.btn-primary]="rangeSelected(5)" [class.btn-secondary]="!rangeSelected(5)">5 minutes</button>
                <button class="btn" (click)="setSelectedRange(10)" [class.btn-primary]="rangeSelected(10)" [class.btn-secondary]="!rangeSelected(10)">10 minutes</button>
                <button class="btn" (click)="customRange = true" [class.btn-primary]="customRange" [class.btn-secondary]="!customRange">Custom</button>
            </div>
            <div class="input-group" *ngIf="customRange">
                <input type="number" [(ngModel)]="minutesPerRange" class="form-control" />
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{intervalType}}</button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" (click)="intervalType = 'minutes'">minutes</a>
                        <a class="dropdown-item" (click)="intervalType = 'seconds'">seconds</a>
                    </div>
                </div>
            </div>
            <div>
                <label>Start</label>
                <select class="selectpicker form-control" [class.has-error]="startHour &gt; endHour" [(ngModel)]="startHour">
                    <option value="4">4 am</option>
                    <option value="5">5 am</option>
                    <option value="6">6 am</option>
                    <option value="7">7 am</option>
                    <option value="8">8 am</option>
                    <option value="9">9 am</option>
                    <option value="10">10 am</option>
                    <option value="11">11 am</option>
                    <option value="12">12 pm</option>
                    <option value="13">1 pm</option>
                    <option value="14">2 pm</option>
                    <option value="15">3 pm</option>
                    <option value="16">4 pm</option>
                    <option value="17">5 pm</option>
                    <option value="18">6 pm</option>
                    <option value="19">7 pm</option>
                    <option value="20">8 pm</option>
                    <option vaule="21">9 pm</option>
                    <option value="22">10 pm</option>
                    <option value="23">11 pm</option>
                </select>
            </div>
            <div *ngIf="reportType == 'Frequency'">
                <div><label>End</label></div>
                <select class="selectpicker form-control" [class.has-error]="startHour &gt; endHour" [(ngModel)]="endHour">
                    <option value="4">4 am</option>
                    <option value="5">5 am</option>
                    <option value="6">6 am</option>
                    <option value="7">7 am</option>
                    <option value="8">8 am</option>
                    <option value="9">9 am</option>
                    <option value="10">10 am</option>
                    <option value="11">11 am</option>
                    <option value="12">12 pm</option>
                    <option value="13">1 pm</option>
                    <option value="14">2 pm</option>
                    <option value="15">3 pm</option>
                    <option value="16">4 pm</option>
                    <option value="17">5 pm</option>
                    <option value="18">6 pm</option>
                    <option value="19">7 pm</option>
                    <option value="20">8 pm</option>
                    <option vaule="21">9 pm</option>
                    <option value="22">10 pm</option>
                    <option value="23">11 pm</option>
                </select>
            </div>
            <div class="error-text" *ngIf="startHour &gt; endHour">
                The time range provided is invalid.
            </div>
        </div>
    </div>
    
</div>