<div class="row" *ngIf="student">
    <div class="col-12">
        <h2>Support Changes</h2>
    </div>
    <div class="col-4">
        <ul>
            <li class="list-item" [class.selected]="item == selected" *ngFor="let item of milestones; index as i">
                <a [id]="'student-setting-device-support-' + i" (click)="setSelected(item)">
                    {{item.title}}&nbsp;{{item.dateString}}
                </a>
            </li>
            <li *ngIf="administrator" class="list-item" [class.selected]="selected?.isNew">
                <a id="student-setting-device-support-new" title="Add new support change" (click)="create()"><i class="fa fa-plus"></i> Add New Support Change</a>
            </li>
        </ul>
    </div>
    <div class="col-8 details" *ngIf="selected">
        <div class="container">
            <div class="row">
                <div class="col-6 form-group">
                    <label>Name</label>
                    <input id="student-setting-device-support-name" class="form-control" maxlength="20" [(ngModel)]="selected.title" [readonly]="!administrator" />
                </div>
                <div class="col-6 form-group">
                    <label>Date Started</label>
                    <div id="student-setting-device-support-date-group" class="input-group">
                        <input id="student-setting-device-support-date" id="dateField" class="form-control" [(ngModel)]="inputDateHandler" [readonly]="!administrator" />
                        <div class="input-group-append dropdown">
                            <button id="selectDate" class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-calendar"></i></button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="selectDate">
                                <ngb-datepicker [startDate]="selectedDateHandler" [(ngModel)]="selectedDateHandler" [displayMonths]="1" [dayTemplate]="t" outsideDays="hide" firstDayOfWeek="7">
                                </ngb-datepicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 form-group">
                    <label>Description</label>
                    <textarea id="student-setting-device-support-desc" class="form-control" rows="7" maxlength="200" [(ngModel)]="selected.description" [readonly]="!administrator"></textarea>
                    <span id="student-setting-device-support-desc-len">Characters remaining: {{200 - selected.description.length}}</span>
                </div>
            </div>
        </div>
        <div class="text-center" *ngIf="administrator">
            <button id="student-setting-device-support-save" class="btn btn-primary" (click)="save()">Save</button>
            <button id="student-setting-device-support-cancel" class="btn btn-secondary" (click)="cancel()">Cancel</button>
            <button id="student-setting-device-support-remove" class="btn btn-danger" (click)="remove()" *ngIf="!isNew()">Remove</button>
        </div>
        <app-loading *ngIf="loading" [absolute]="true" text="Saving" style="z-index: 100;"></app-loading>
    </div>
</div>