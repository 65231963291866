<div class="container-fluid">
    <div class="row" *ngIf="!readonly">
        <div class="col-12">
            <h3>Documents</h3>
        </div>
        <div class="col-12">
            <label>Upload New Document</label>
            <br/>
            <input #importFileElement (ngModel)="valid" class="btn" type="file" observeFiles/>
        </div>
        <div class="col-12">
            <button class="btn btn-primary" (click)="loadImportFile()" [disabled]="!valid || saving"><i *ngIf="saving" class="fa fa-circle-o-notch fa-spin"></i> Upload</button>

            <app-loading *ngIf="saving" text="Saving"></app-loading>
        </div>
    </div>
</div>
<div class="container-fluid" *ngIf="documents">
    <table class="table">
        <thead>
            <tr>
                <th>File Name</th>
                <th>Upload Date</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let d of documents.documents">
                <td><a [download]="d.name" [href]="d.link" target="_blank">{{d.name}}</a></td>
                <td>
                    {{d.lastUpdated}}
                </td>
                <td>
                    <a class="link-item" (click)="d.delete()" *ngIf="!readonly">
                        <i class="fa fa-trash" *ngIf="!d.deleting"></i>
                        <i class="fa fa-circle-o-notch fa-spin" *ngIf="d.deleting"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<app-loading *ngIf="loading"></app-loading>