<div class="scroll-header no-print">
    <h2>{{student?.displayName}} - Snapshot</h2>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-3 no-print">
            <input *ngIf="restrictions.reports == 'Admin'" type="date" [(ngModel)]="currentDay"/>
        </div>
        <div class="col-4 no-print">
            <div *ngIf="!preview">
                <span class="daysOfWeekOptions" *ngFor="let day of displayDays">
                    <a [class.link-item]="displayDay != day.date" (click)="currentDay = day.date"><i class="fa fa-circle" [class.green-dot]="day.dataSaved == 'saved'" [class.yellow-dot]="day.dataSaved == 'modified'" *ngIf="day.dataSaved" title="The day's summary has been saved"></i>{{day.text}}</a>
                </span>
            </div>
        </div>
        <div class="col-5 no-print">
            <button class="btn btn-outline-secondary dropdown-toggle" style="float:right" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Saved Reports</button>
            <div class="dropdown-menu">
                <a class="dropdown-item" (click)="currentDay = r" *ngFor="let r of reportList">{{r}}</a>
            </div>
            <button class="btn btn-primary" style="float:right" (click)="print()"><i class="fa fa-print"></i> Print</button>
            <button *ngIf="!preview" (click)="preview = !preview" class="btn btn-primary" style="float:right">Review</button>
            <button *ngIf="preview && restrictions.reports == 'Admin'" (click)="preview = !preview" class="btn btn-primary" style="float:right">Edit</button>
            <button *ngIf="restrictions.reports == 'Admin'"  class="btn btn-primary" (click)="save()"><i class="fa fa-floppy-o"></i> Save</button>
        </div>
        <div class="col-xl-7 col-12 no-print" *ngIf="!preview">
            <div>
                <label><input type="checkbox" [(ngModel)]="showLegend"/> Show Legend</label>
            </div>
            <table class="table">
                <thead>
                    <th><input type="checkbox" [(ngModel)]="showAll"/> Show</th>
                    <th>Dislay Name</th>
                    <th style="width:100px;">Summary</th>
                    <th style="width:100px;" *ngIf="showStats">Day/Day</th>
                    <th style="width:100px;" *ngIf="showStats">Week/Week</th>
                </thead>
                <tbody>
                    <tr *ngFor="let behavior of behaviors">
                        <td class="form-group text-center">
                            <input type="checkbox" [(ngModel)]="behavior.show"/>
                        </td>
                        <td class="form-group">
                            <input class="form-control" [(ngModel)]="behavior.displayText" />
                        </td>
                        <td class="form-group text-center">
                            <app-report-value [faces]="behavior.faces" [snapshotConfig]="snapshotConfig" [dayOffset]="weekdayOffset" [allowSelection]="!hasTargetEval(behavior)"></app-report-value>
                        </td>
                        <td class="number-area" *ngIf="showStats">
                            <span *ngIf="behavior.stats">
                                <span class="main-number">{{getStatDisplayValue(behavior, behavior.stats.day.count)}}</span>
                                <span class="sub-number">({{behavior.stats.day.modifier}}{{getStatDisplayValue(behavior, behavior.stats.day.delta)}})</span>
                            </span>
                            <div class="number-area text-center" *ngIf="!behavior.stats">
                                <i class="fa fa-circle-o-notch fa-spin"></i>
                            </div>
                        </td>
                        <td class="number-area" *ngIf="showStats">
                            <span *ngIf="behavior.stats">
                                <span class="main-number">{{getStatDisplayValue(behavior, behavior.stats.week.count)}}</span>
                                <span class="sub-number">({{behavior.stats.week.modifier}}{{getStatDisplayValue(behavior, behavior.stats.week.delta)}})</span>
                            </span>
                            <div class="number-area text-center" *ngIf="!behavior.stats">
                                <i class="fa fa-circle-o-notch fa-spin"></i>
                            </div>
                        </td>
                        <td *ngIf="behavior.isDuration">
                            <i class="fa fa-clock-o"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr ></tr>
                </tbody>
            </table>
        </div>
        <div class="col-xl-5 col-12 no-print" *ngIf="!preview">
            <label>Week's observations</label>
            <div class="NgxEditor__Wrapper message-area">
                <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                <ngx-editor
                  [editor]="editor"
                  [(ngModel)]="message"
                  [disabled]="false"
                  [placeholder]="'Type here...'"
                ></ngx-editor>
            </div>
            <div>
                <label>Notes (Reference Only)</label>
                <div class="notes-area" style="height:35vh; overflow-y:scroll">
                    <pre>{{notes}}</pre>
                </div>
            </div>
        </div>
        <div id="preview" class="col-12 hide-items print-content" [class.show-items]="preview">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 print-header">
                        <img *ngIf="student && student.license == '2022030410775b87a78d4df98b0026ec42fe1afd'" src="assets/images/customers/logo-relife.png"/>
                        <h2>Weekly report</h2>
                        <div *ngIf="student">
                            <span style="font-weight:bold">Student:</span> {{student.details.firstName}} {{student.details.lastName}}
                        </div>
                        <div>
                            <span style="font-weight:bold">Date:</span> {{displayDay}}
                        </div>
                    </div>
                    <div class="col-md-7 col-xs-12">
                        <table class="table week-report">
                            <thead>
                                <th>Summary</th>
                                <th class="header-day" *ngFor="let day of displayDays">{{day.text}}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let behavior of getShownBehaviors()">
                                    <td class="week-name">
                                        {{behavior.displayText}}
                                    </td>
                                    <td class="text-center" *ngFor="let day of displayDays; index as i">
                                        <app-report-value [faces]="behavior.faces" [snapshotConfig]="snapshotConfig" [dayOffset]="day.offset" [allowSelection]="false"></app-report-value>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div *ngIf="showLegend" class="legend">
                            <label>Legend</label>
                            <table class="table week-report">
                                <thead>
                                    <tr>
                                        <th>Legend</th>
                                        <th *ngFor="let leg of snapshotConfig?.measurements">
                                            <app-report-value [faces]="[{ face: leg.name }]" [snapshotConfig]="snapshotConfig" [dayOffset]="0" [allowSelection]="false"></app-report-value>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let leg of getLegend()">
                                        <td>
                                            {{getDisplayName(leg)}}
                                            <span *ngIf="getTimeLegend(leg)">
                                                <br/>
                                                {{getTimeLegend(leg)}}
                                            </span>
                                        </td>
                                        <td *ngFor="let item of leg?.measurements; let i = index">
                                            {{getLegendSupplement(leg, item, i, 'goals')}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-5 col-12" style="border-left: solid #000 1px;">
                        <label>Observations</label>
                        <div [innerHTML]="getMessage()"></div>
                    </div>
                    <div class="col-12" style="margin-top:100px;border-top: solid #000 1px">
                        powered by <img style="width:150px;vertical-align:bottom" src="assets/images/sm-text-logo.gif"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="saving" text="saving"></app-loading>

<app-loading *ngIf="loading"></app-loading>