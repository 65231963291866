<h2>Student Template</h2>
<div>
    <label>Name</label>
    <input class="form-control" [(ngModel)]="template.name"/>
</div>
<div>
    <label>Tag</label>
    <app-mtt-tags [(tagField)]="template.tags" [edit]="false"></app-mtt-tags>
</div>
<div>
    <label>Description</label>
    <textarea class="form-control" [(ngModel)]="template.desc"></textarea>
</div>
<div class="container-fluid main-tab-area">
    <div class="row">
        <div class="col-12 navigation">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link" [class.active]="selectedTab == 'behaviors'" [routerLink]="['/manage/templates/behaviors']" queryParamsHandling="merge">Behaviors</a>
                </li>
                <li class="nav-item" *ngIf="responseAccess">
                    <a class="nav-link" [class.active]="selectedTab == 'responseTracking'" [routerLink]="['/manage/templates/responseTracking']" queryParamsHandling="merge">Response Tracking</a>
                </li>
                <li class="nav-item" *ngIf="abcAccess">
                    <a class="nav-link" [class.active]="selectedTab == 'abc'" [routerLink]="['/manage/templates/abc']" queryParamsHandling="merge">ABC <span style="font-style:italic">(beta)</span></a>
                </li>
                <li class="nav-item" *ngIf="milestoneAccess">
                  <a class="nav-link" [class.active]="selectedTab == 'milestones'" [routerLink]="['/manage/templates/milestones']" queryParamsHandling="merge">Support Changes</a>
                </li>
                <li class="nav-item" *ngIf="scheduleAccess">
                  <a class="nav-link" [class.active]="selectedTab == 'schedule'" [routerLink]="['/manage/templates/schedule']" queryParamsHandling="merge">Schedule</a>
                </li>
                <li class="nav-item" *ngIf="deviceAccess">
                  <a class="nav-link" [class.active]="selectedTab == 'devices'" [routerLink]="['/manage/templates/devices']" queryParamsHandling="merge">Devices</a>
                </li>
                <li class="nav-item" *ngIf="teamAccess">
                  <a class="nav-link" [class.active]="selectedTab == 'team'" [routerLink]="['/manage/templates/team']" queryParamsHandling="merge">Team</a>
                </li>
                <li class="nav-item" *ngIf="notificationAccess">
                  <a class="nav-link" [class.active]="selectedTab == 'notifications'" [routerLink]="['/manage/templates/notifications']" queryParamsHandling="merge">Notifications</a>
                </li>
              </ul>
        </div>
    </div>
    <div class="row" style="min-height:200px">
        <div class="col-12" *ngIf="selectedTab == 'behaviors'">
            <app-behaviors [student]="template"></app-behaviors>
        </div>
        <div class="col-12" *ngIf="selectedTab == 'responseTracking'">
            <app-response-tracking [student]="template"></app-response-tracking>
        </div>
        <div class="col-12" *ngIf="selectedTab == 'devices'">
            <app-devices [student]="template" [templatesOnly]="true"></app-devices>
        </div>
    </div>
</div>
<div class="controls">
    <button class="btn btn-primary" (click)="save()"><i class="fa" [class.fa-floppy-o]="!saving" [class.fa-floppy-o]="!saving" [class.fa-circle-o-notch]="saving" [class.fa-spin]="saving"></i> Save</button>
    <button class="btn btn-secondary" (click)="cancel()"><i class="fa fa-times"></i> Cancel</button>
    <button class="btn btn-danger" *ngIf="template.name" (click)="delete()"><i class="fa fa-trash"></i> Delete</button>
</div>