import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  UserService, LicenseDetails, LicenseDetailsWithUsage, SnapshotConfig,
  ApiClientService, moment
} from '../../../lib';

class LicenseDetailsEx {
  public errors: {
    customer?: boolean;
    singleCount?: boolean;
    multiCount?: boolean;
    expiration?: boolean;
    admins?: boolean;
  } = {};

  private _expiration: moment.Moment;

  get license() {
    return this.licenseDetails.license;
  }

  get customer() {
    return this.licenseDetails.customer;
  }
  set customer(val: string) {
    this.errors.customer = !val;
    this.licenseDetails.customer = val;
  }
  get admins() {
    return this.licenseDetails.admins? this.licenseDetails.admins.join(', ') : '';
  }
  set admins(val: string) {
    const parts = val.split(',').map(x => x.toLowerCase().trimLeft().trimRight());
    this.errors.admins = parts.find(x => !x || x.length === 0) !== undefined || parts.length === 0;
    this.licenseDetails.admins = parts;
  }

  get snapshotConfigNames() {
    let config: SnapshotConfig = this.licenseDetails.features.snapshotConfig;
    if(!config) {
      config = {
        low: '@frown',
        medium: '@meh',
        high: '@smile',
        measurements: [
          { name: '@smile', order: 0 },
          { name: '@meh', order: 1 },
          { name: '@frown', order: 2 }
        ]
      };
      
      this.licenseDetails.features.snapshotConfig = config;
    }
    if(!config.measurements) {
      if(!config.low) {
        config = {
          low: '@frown',
          medium: '@meh',
          high: '@smile',
          measurements: [
            { name: '@smile', order: 0 },
            { name: '@meh', order: 1 },
            { name: '@frown', order: 2 }
          ]
        };
      } else {
        config.measurements = [
            { name: config.high, order: 0 },
            { name: config.medium, order: 1 },
            { name: config.low, order: 2 }
          ];
      }
    }

    return config.measurements.map(x => x.name).join('\n');
  }
  set snapshotConfigNames(val: string) {
    let config: SnapshotConfig = this.licenseDetails.features.snapshotConfig;
    if(!config) {
      config = {
        low: '',
        medium: '',
        high: '',
        measurements: []
      };
      this.licenseDetails.features.snapshotConfig = config;
    }
    config.measurements = val.split('\n').map((x, i) => {
      return {
        name: x,
        order: i
      };
    });
    if(config.measurements.length === 0) {
      return;
    }
    config.high = config.measurements[0].name;
    config.medium = config.measurements[Math.ceil(config.measurements.length / 2) - 1].name;
    config.low = config.measurements[config.measurements.length - 1].name;
  }
     

  get expiration() {
    return this._expiration.format('yyyy-MM-DD');
  }
  set expiration(val: string) {
    if(!val) {
      this._expiration = moment().add(1, 'year');
    } else {
      this._expiration = moment(val);
    }
    this.licenseDetails.expiration = this._expiration.format('MM/DD/yyyy');
  }

  get singleCount() {
    return this.licenseDetails.singleCount.toString();
  }
  set singleCount(val: string) {
    const value = Number.parseInt(val);
    this.errors.singleCount = Number.isNaN(value);
    if(!this.errors.singleCount) {
      this.licenseDetails.singleCount = value;
    }
  }

  get singleRemaining() {
    return this.licenseDetails.singleCount - this.licenseDetails.singleUsed;
  }

  get multiCount() {
    return this.licenseDetails.multiCount.toString();
  }
  set multiCount(val) {
    const value = Number.parseInt(val);
    this.errors.multiCount = Number.isNaN(value);
    if(!this.errors.multiCount) {
      this.licenseDetails.multiCount = value;
    }
  }

  get usage() {
    return this.licenseDetails.usage || [];
  }

  get features() {
    return this.licenseDetails?.features || {};
  }

  get customizeSnapshot() {
    if(!this.licenseDetails.features) {
      return false;
    }

    return this.licenseDetails?.features?.snapshotConfig? true : false;
  }

  set customizeSnapshot(val: boolean) {
    if(val) {
      if(!this.licenseDetails?.features?.snapshotConfig) {
        this.licenseDetails.features.snapshotConfig = {
          high: 'H',
          medium: 'M',
          low: 'L',
          measurements: []
        };
      }
    } else {
      delete this.licenseDetails?.features?.snapshotConfig;
    }
  }

  public licenseDetails: LicenseDetailsWithUsage;

  constructor(details: LicenseDetailsWithUsage) {
    this.licenseDetails = details;
    if(!details.features) {
      details.features = {
        dashboard: true,
        snapshot: true,
        browserTracking: true,
        download: true,
        manage: false,
        supportChanges: false,
        schedule: true,
        devices: true,
        behaviorTargets: true,
        response: false,
        emailTextNotifications: true,
        behaviorTracking: true,
        serviceTracking: false,
        serviceProgress: false,
        duration: true
      };
    }
    if(!details.expiration) {
      details.expiration = moment().add(1, 'year').format('MM/DD/yyyy');
    }
    this._expiration = moment(details.expiration);
  }

  isValid(): boolean {
    this.customer = this.customer;
    this.singleCount = this.singleCount;
    this.multiCount = this.multiCount;
    this.expiration = this.expiration;
    this.admins = this.admins;

    return !(this.errors.customer ||
           this.errors.singleCount ||
           this.errors.multiCount ||
           this.errors.expiration ||
           this.errors.admins);
  }
}

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.css']
})
export class LicensesComponent implements OnInit {
  public licenses: LicenseDetailsEx[] = [];
  public currentLicenses: LicenseDetailsEx[] = [];
  public newCustomer: LicenseDetailsEx;
  public impersonateUserId: string = '7490b86b-a83b-493a-be63-28653497817e';

  public showStats = false;
  public get showConfig() {
    return !this.showStats;
  }
  public set showConfig(val: boolean) {
    this.showStats = !val;
  }

  public get displayLicenses() {
    return this.currentLicenses;
  }

  constructor(private apiClient: ApiClientService,
    private userService: UserService,
    private router: Router) { }

  ngOnInit(): void {
    this.buildBlankCustomer();
    this.userService.user.subscribe(async user => {
      if(user && !user.admin) {
        this.router.navigate(['/']);
      }
    });
    this.apiClient.getLicenses().then((licenses) => {
      this.licenses = licenses.map(x => new LicenseDetailsEx(x)).sort((a, b) => { return a.customer.localeCompare(b.customer)});
      this.currentLicenses = this.licenses.filter(x => x.expiration >= moment().format('yyyy-MM-DD'));
    });
  }

  buildBlankCustomer() {
    this.newCustomer = new LicenseDetailsEx({
      customer: '',
      emailDomain: '',
      admins: [],
      start: moment().format('yyyy-MM-DD'),
      expiration: moment().add(1, 'year').format('MM/DD/yyyy'),
      singleCount: 0,
      singleUsed: 0,
      multiCount: 0,
      usage: [],
      studentTemplates: [],
      tags: {
        devices: []
      },
      features: {
        abc: false,
        dashboard: true,
        behaviorTargets: true,
        browserTracking: true,
        devices: true,
        download: true,
        emailTextNotifications: true,
        manage: false,
        manageStudentTemplates: false,
        manageResponses: false,
        notifications: true,
        response: false,
        schedule: true,
        snapshot: true,
        snapshotConfig: undefined,
        supportChanges: true,
        intervalWBaseline: false,
        behaviorTracking: true,
        serviceProgress: false,
        serviceTracking: false,
        duration: true
      }
    });
  }

  create() {
    this.save(this.newCustomer);  
  }

  async save(license: LicenseDetailsEx) {
    if(!license.isValid()) {
      alert('License invalid')
      return;
    }
    try {
      const result = await this.apiClient.putLicense({
        ...license.licenseDetails,
        start: moment(license.licenseDetails.start).format('yyyy-MM-DD'),
        expiration: moment(license.licenseDetails.expiration).format('yyyy-MM-DD')
      });
      if(!this.licenses.find(x => x.license === result.license)) {
        this.licenses.unshift(new LicenseDetailsEx({
          usage: [],
          ...result
        }));
        this.buildBlankCustomer();
      }
    } catch (err) {
      alert(err.message);
    }
  }

  async delete(license: LicenseDetailsEx) {
    if(!license) {
      return;
    }

    if(license.license) {
      try {
        await this.apiClient.deleteLicense(license.license);
      } catch (err) {
        alert(err.message);
        return;
      }
    }

    const index = this.licenses.findIndex(x => x === license);
    if(index >= 0) {
      this.licenses.splice(index, 1);
    }
  }

  async cancelNew() {
    this.buildBlankCustomer();
  }

  showStatsArea(license: LicenseDetails) {
    if(!license || !license['stats']) {
      return false;
    }
    return true;
  }

  impersonate() {
    localStorage.setItem('impersonateUserId', this.impersonateUserId);
  }
}
