import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { amplifyConfig } from '../config/aws-exports';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { MytaptrackEnvironment } from '../config/environment';
declare const gapi: any;
declare const launchUri: any;

@Injectable({
  providedIn: 'root'
})
export class AuthClientService {
  static setEnvironment(environment: MytaptrackEnvironment) {
    Amplify.configure(amplifyConfig(environment));
  }

  public signedIn: boolean = false;
  public session: BehaviorSubject<CognitoUserSession> = new BehaviorSubject(null);
  public name: string;
  public cognitoUser: CognitoUser;
  public email:any;
  public password:any;
  public previousUrl: string;
  public nextRefresh: Date;

  get token(): string { return this.session.getValue()?.getIdToken().getJwtToken(); }
  get group(): string[] { 
    if(!this.session.getValue()) {
      return [];
    }
    return this.session.getValue().getAccessToken()?.payload['cognito:groups']?.filter(x => x !== 'pretest');
  }


  constructor(private router: Router, private http: HttpClient) {
    // gapi?.load('auth2', () => {
    //   gapi.auth2.init({ client_id: environment.googleClientId });
    // });
    Hub.listen('auth', async ({ payload }) => {
      const { event } = payload;
      if(event === 'autoSignIn' || event === 'signIn' || event === 'cognitoHostedUI') {
        const user = this.cognitoUser;
        this.cognitoUser = await Auth.currentAuthenticatedUser();
        if((user as any)?.username != this.cognitoUser?.getUsername()) {
          this.processUserSession();
        }
      } else if (event === 'autoSignIn_failure' || event === 'signOut') {
        this.cognitoUser = undefined;
      }
    });
    this.processUserSession();
  }

  async checkAuthentication() {
    try {
      const session = await Auth.currentSession();
      if(!session || !session.isValid()) {
        this.signOut();
        return;
      }
      if(session) {
        const user = await Auth.currentAuthenticatedUser();
        this.cognitoUser = user;
        this.session.next(session);
      }
    } catch (err) {
      return false;
    }
  }

  async processUserSession() {
    try {
      this.cognitoUser = await Auth.currentAuthenticatedUser();
      if(this.session.getValue()) {
        return;
      }
      const session = await Auth.currentSession();

      if(!session?.getIdToken()?.getJwtToken()) {
        this.session.next(null);
      } else {
        this.nextRefresh = new Date(new Date().getTime() + (1000 * 60 * 5));
        this.session.next(session);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async loadGoogleAuth2() {
    // if(gapi.auth2) {
    //   return;
    // }
    // await new Promise<void>((resolve) => {
    //   gapi.load('auth2', function() {
    //     resolve();
    //   });
    // });
    // gapi.auth2.init();
  }

  async signOut() {
    
    // const authInstance = await gapi.auth2.getAuthInstance();
    // await gapi.auth2.getAuthInstance().signOut();
    // window.location.href = `https://${environment.cognitoLoginDomain}/oauth2/logout?client_id=${environment.cognitoClientId}&logout_uri=${environment.cognitoCallbackUrl}`;
    try {
      // window.location.href = `https://${environment.cognitoLoginDomain}/logout?client_id=${environment.cognitoClientId}&logout_uri=${environment.cognitoCallbackUrl}`;
      await Auth.signOut({global: true});
      this.session.next(null);
      this.previousUrl = null;  
    } catch (err) {
      console.error(err);
    }
  }

  //function for aws registration
  async register(email, password, name) {
    this.email = email;
    const attributeList = [];
    var dataName = {
      Name : 'name',
      Value : name
    };
    const result = await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        ...attributeList
      }
    });
    return result;
  }

  //function for confirm aws user registration
  async confirmAuthCode(code) {
    return await Auth.confirmSignUp(this.email, code);
  }

  //funtion to resend confirm auth code 
  async resendCode(email) {
    return await Auth.resendSignUp(email);
  }

  //function for forgot password
  async forgotPassword(email){
    return await Auth.forgotPassword(email);
  }

  //function for reset forgot password
  async resetPassword(verificationCode, newPassword, email){
    return await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
  }

  async setPassword(oldPassword: string, newPassword: string) {
    await Auth.changePassword(this.cognitoUser, oldPassword, newPassword);
  }
}
