<span class="smiles" *ngIf="!allowSelection">
    <span *ngIf="!isFace()" [style.color]="getFaceColor(behavior)">
        {{getStatusName(behavior, weekdayOffset)}}
    </span>
    <i *ngIf="isFace()" class="fa {{getFace()}} large" aria-hidden="true"></i>
</span>
<div dropdown class="btn-group smiles" *ngIf="allowSelection">
    <button type="button" dropdownToggle aria-controls="reportStatusMenu" class="btn select-button dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
        <span *ngIf="!isFace()" [style.color]="getFaceColor(behavior)">
            {{getStatusName(behavior)}}
        </span>
        <i *ngIf="isFace()" class="fa {{getFace()}} large" aria-hidden="true"></i>
    </button>
    <ul id="reportStatusMenu" *dropdownMenu class="dropdown-menu" role="menu">
        <div *ngFor="let item of snapshotConfig.measurements" [style.color]="getStatusColor(item.name)">
            <a (click)="setFace(item.name)">
                <span *ngIf="!isTextFace(item.name)">{{item.name}}</span>
                <i *ngIf="isTextFace(item.name)" class="fa {{getFaceFromText(item.name)}} large" aria-hidden="true"></i>
            </a>
        </div>
    </ul>
</div>