<div class="container-fluid" style="margin-top:20px" *ngIf="!loading">
    <div class="row">
        <div class="col-4">
            <div>
                <label>Search</label>
                <input class="form-control" [(ngModel)]="searchText" />
            </div>
            <div *ngIf="searchText">
                <h2>App Results:</h2>
                <ul>
                    <li *ngFor="let item of searchResults" [class.selected]="selected && item.id == selected.id && item.name == selected.name">
                        <a (click)="setSelected(item)">{{item.name}}</a>
                    </li>
                    <li>
                        <a (click)="createApp()"><i class="fa fa-plus"></i> Add new app</a>
                    </li>
                </ul>
            </div>
            <div *ngIf="!searchText">
                <h2>Apps:</h2>
                <ul>
                    <li *ngFor="let item of activeApps" [class.selected]="selected && item.device.id == selected.device.id && item.device.name == selected.device.name">
                        <a (click)="setSelected(item)">{{item.name ?? 'Un-named App'}}</a>
                    </li>
                    <li>
                        <a (click)="createApp()"><i class="fa fa-plus"></i> Add new app</a>
                    </li>
                </ul>
                <h2>Apps without students:</h2>
                <ul>
                    <li *ngFor="let item of emptyApps" [class.selected]="selected && item.device.id == selected.device.id && item.device.name == selected.device.name">
                        <a (click)="setSelected(item)">{{item.name}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-8 select-details" *ngIf="selected">
            <div>
                <label>Mobile Device Name:</label>
                <input class="form-control" [(ngModel)]="selected.device.name">
            </div>
            <div>
                <label>Tags</label>
                <app-mtt-tags [(tagField)]="selected.tags"></app-mtt-tags>
            </div>
            <div>
                <button class="btn btn-primary" (click)="updateAppName()"><i class="fa fa-save"></i> Update</button>
                <button class="btn btn-primary" (click)="generateQrCode()"><i *ngIf="!qrLoading" class="fa fa-qrcode"></i><i *ngIf="qrLoading" class="fa fa-circle-o-notch fa-spin"></i> QR Code</button>
                <button class="btn btn-primary" (click)="reassign()"> <i *ngIf="reassigning" class="fa fa-circle-o-notch fa-spin"></i> Re-assign to new device</button>
            </div>
            <div *ngIf="selected.token">
                <device-app [hideName]="true" [administrator]="true" [selected]="selected.assignments[0].app" [student]="selected.assignments[0].student" [groupsEnabled]="groupsEnabled"></device-app>
            </div>
            <div *ngIf="!selected.token">
                <h4>Students:</h4>
                <div class="accordion" id="settingsAccordion">
                    <div class="card" *ngFor="let item of selected.assignments">
                        <div class="card-header" id="heading{{item.studentId}}">
                            <h2 class="mb-0">
                                <a class="control-link" style="right: 5px;top: 10px;" *ngIf="processing"><i class="fa fa-circle-o-notch fa-spin"></i></a>
                                <a class="control-link" style="right: 5px;top: 10px;" (click)="item.delete()" *ngIf="!processing">
                                    <i class="fa fa-trash" *ngIf="!item.deleting"></i>
                                    <i class="fa fa-circle-o-notch fa-spin" *ngIf="item.deleting"></i>
                                </a>
                                <a class="control-link" style="right: 30px;top: 10px;" (click)="copyFrom(item)" *ngIf="!processing"><i class="fa fa-copy"></i></a>
                                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#s' + item.studentId" [attr.aria-expanded]="'false'" [attr.aria-controls]="'s' + item.studentId">
                                    {{item.name}}
                                    <span *ngFor="let g of item.groups" class="tag group">{{g}}</span>
                                </button>
                            </h2>
                        </div>
                        <div [attr.id]="'s' + item.studentId" class="collapse" [attr.aria-labelledby]="'heading' + item.studentId" data-parent="#settingsAccordion">
                            <div class="card-body settings-accordian-body">
                                <device-app [hideName]="true" [administrator]="true" [abcEnabled]="features?.abc" [selected]="item.app" [student]="item.student" [groupsEnabled]="groupsEnabled"></device-app>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="addStudent">
                        <h3>Adding student to app</h3>
                        <label>Select a student:</label>
                        <select class="form-conrol" [(ngModel)]="selectedStudentId">
                            <option *ngFor="let student of nonSelectedStudents" [value]="student.studentId">{{getStudentName(student)}}</option>
                        </select>
                        <div>
                            <button class="btn btn-primary" (click)="addStudentToApp(selectedStudentId)">Create</button>
                            <button class="btn btn-secondary" (click)="addStudent = false">Cancel</button>
                        </div>
                    </div>
                    <div *ngIf="!addStudent">
                        <a (click)="showSelectStudent()"><i class="fa fa-plus"></i> Add student to app</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="loading"></app-loading>
