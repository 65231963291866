import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MytaptrackWebCommonComponent } from './web-common.component';
import { MytaptrackShellComponent } from './components/mytaptrack-shell/mytaptrack-shell.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { 
  DateDropdownComponent, DateSelectionComponent, DownloadDataComponent, 
  EditDropdownComponent, MttTagsComponent, NotesComponent, TimeInputComponent,
  DeviceAppComponent, NoteComponent
} from './components';
import {
  ApiClientService, AuthClientService, ChartUtilsService, DateTimeService, 
  ErrorHandlingService, UserService, ViewerConfigService
} from './services';
import { NgbDatepicker, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { HeaderComponent } from './components/header/header.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
  declarations: [
    MytaptrackWebCommonComponent,
    MytaptrackShellComponent,
    DateDropdownComponent, 
    DateSelectionComponent, 
    DownloadDataComponent, 
    EditDropdownComponent, 
    MttTagsComponent,
    NotesComponent, 
    TimeInputComponent,
    HeaderComponent,
    LoadingComponent,
    DeviceAppComponent,
    NoteComponent
  ],
  providers: [
    ApiClientService, 
    AuthClientService, 
    ChartUtilsService, 
    DateTimeService, 
    ErrorHandlingService, 
    UserService, 
    ViewerConfigService
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    ReactiveFormsModule,
    NgChartsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgbModule,
    NgxPrintModule,
    QRCodeModule,
  ],
  exports: [
    MytaptrackShellComponent,
    DateDropdownComponent, 
    DateSelectionComponent, 
    DownloadDataComponent, 
    EditDropdownComponent, 
    MttTagsComponent,
    NotesComponent,
    TimeInputComponent,
    HeaderComponent,
    LoadingComponent,
    DeviceAppComponent,
    QRCodeModule,
  ],
  bootstrap: [
  ]
})
export class MytaptrackWebCommonModule { }
