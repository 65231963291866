<div class="header d-print-none">
    <div class="row mytaptrack-header">
        <div class="col-12">
            <span *ngIf="isMobile" class="navbar" >
                <span *ngIf="user && features && features.dashboard">
                    <a id="dashboardLink" *ngIf="features.dashboard" [routerLink]="['']" [queryParams]="{ studentId: studentId}">
                        <i class="fa fa-bar-chart" aria-hidden="true"></i> Dashboard
                    </a>
                    <span>&nbsp;|&nbsp;</span>
                </span>
                <span *ngIf="user && features && features.browserTracking">
                    <a *ngIf="features.dashboardTracking" [routerLink]="['student/tracking']" [queryParams]="{ studentId: studentId}">
                        <i class="fa fa-flash" aria-hidden="true"></i> Tracking
                    </a>
                </span>
                <a class="navbar-brand" [routerLink]="['']" queryParamsHandling="merge" (click)="router.navigate([''])">
                    <img src="assets/images/sm-text-logo.gif" alt="mytaptrack logo" />
                </a>
            </span>
            <nav *ngIf="!isMobile" class="navbar navbar-expand-sm">
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <a class="navbar-brand" [routerLink]="['']" queryParamsHandling="merge" (click)="router.navigate([''])">
                    <img src="assets/images/sm-text-logo.gif" alt="mytaptrack logo" />
                </a>

                <div *ngIf="!isMobile" class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav" *ngIf="user && features">
                        <li class="nav-item text-center" *ngIf="!manage && studentId && features.dashboard && canAccessDashboard">
                            <a [routerLink]="['']" [queryParams]="{ studentId: studentId}">
                                <i class="fa fa-bar-chart" aria-hidden="true"></i> Dashboard
                            </a>
                        </li>
                        <li *ngIf="!manage && studentId && features.dashboard && canAccessDashboard">|</li>
                        <li class="nav-item text-center" *ngIf="!manage && reportAccess && studentId && features.snapshot">
                            <a [routerLink]="['student/report']" [queryParams]="{ studentId: studentId}">
                                <i class="fa fa-smile-o" aria-hidden="true"></i> Snapshot
                            </a>
                        </li>
                        <li *ngIf="!manage && reportAccess && studentId && features.snapshot">|</li>
                        <li class="nav-item text-center" *ngIf="!manage && dataAdmin && studentId && features.browserTracking">
                            <a [routerLink]="['student/tracking']" [queryParams]="{ studentId: studentId}">
                                <i class="fa fa-flash" aria-hidden="true"></i> Tracking
                            </a>
                        </li>
                        <li *ngIf="!manage && dataAdmin && studentId && features.browserTracking">|</li>
                        <li class="nav-item text-center" *ngIf="!manage && studentId">
                            <a [routerLink]="['student/settings']" [queryParams]="{ studentId: studentId}">
                                <i class="fa fa-gear" aria-hidden="true"></i> Settings
                            </a>
                        </li>
                        <li *ngIf="!manage && studentId">|</li>
                        <li dropdown class="nav-item  text-center" *ngIf="!manage && studentId && features.download && canAccessDashboard">
                            <a [routerLink]="['student/download']" [queryParams]="{ studentId: studentId }">
                                <i class="fa fa-download" aria-hidden="true"></i> Download
                            </a>
                        </li>
                        <li *ngIf="!manage && studentId && features.download && canAccessDashboard">|</li>
                        <li class="nav-item text-center"  *ngIf="manage">
                            <a href="/">
                                <i class="fa fa-area-chart" aria-hidden="true"></i> Portal
                            </a>
                        </li>
                        <li *ngIf="manage">|</li>
                        <li class="nav-item text-center"  *ngIf="manageArea">
                            <a [routerLink]="['manage']" [queryParams]="{}">
                                <i class="fa fa-key" aria-hidden="true"></i> Manage
                            </a>
                        </li>
                        <li *ngIf="manageArea">|</li>
                        <li class="nav-item text-center">
                            <a href="https://mytaptrack.helpjuice.com" target="blank"><i class="fa fa-question" aria-hidden="true"></i> Help</a>
                        </li>
                    </ul>

                    <ul class="navbar-nav ml-auto" *ngIf="user">
                        <li dropdown class="nav-item active btn-group" placement="bottom right" autoClose="true">
                            <a id="profileDropdownButton" dropdownToggle
                                    class="dropdown-toggle profile-button" aria-controls="profileDropdownMenu">
                                {{user.details.name}} <span class="caret"></span>
                            </a>
                            <ul id="profileDropdownMenu" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="profileDropdownButton">
                                <li role="menuitem">
                                    <a class="dropdown-item" ngbDropdownItem (click)="navigateToProfile()">
                                        <i class="fa fa-user-o" aria-hidden="true"></i>&nbsp;&nbsp;Profile</a>    
                                </li>
                                <li role="menuitem" ngbDropdownItem *ngIf="impersonating">
                                    <a (click)="exitImpersonate()">Exit Impersonation</a>
                                </li>
                                <li role="menuitem" ngbDropdownItem *ngIf="mytaptrackAdmin">
                                    <a [routerLink]="['admin/licenses']" [queryParams]="{ studentId: studentId }">
                                        <i class="fa fa-address-book" aria-hidden="true"></i>&nbsp;&nbsp;Licenses
                                    </a>
                                </li>
                                <li role="menuitem">
                                    <a class="dropdown-item" ngbDropdownItem (click)="logout()">
                                        <i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp;&nbsp;Logout</a>    
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul class="navbar-nav ml-auto" *ngIf="!user">
                        <li class="nav-item active">
                            <a class="nav-link" (click)="login()">Login</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>
