<div class="settings" *ngIf="showSettings">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2>Behaviors</h2>
                <div>
                    <div class="tag" *ngFor="let item of selectedBehaviors">{{item}} <a (click)="removeBehavior(item)"><i class="fa fa-times"></i></a></div>
                    <div *ngIf="selectedBehaviors.length == 0" class="tag-all">All Behaviors</div>
                </div>
                <app-edit-dropdown [(value)]="behaviorEditVal" [optionsEx]="behaviorNames" [hintsMax]="behaviorNames.length"></app-edit-dropdown>
                <button class="btn btn-primary" (click)="addBehavior()">Filter Report</button>
            </div>
            <div class="col-12">
                <h2>Report Types</h2>
                
            </div>
            <div class="col-6" [class.not-selected]="program.reportType != 'beforeDate'">
                <label><input type="radio" name="reportType" value="beforeDate" [(ngModel)]="program.reportType"/>&nbsp;Date range report</label>
                <div>
                    <p>
                        This report type uses data from all students tracked during a specific date range.
                    </p>
                </div>
                <label class="form-label">Start Date</label>
                <input type="date" class="form-control" [(ngModel)]="program.startDate"/>
                <label class="form-label">Started before</label>
                <input type="date" class="form-control" [(ngModel)]="program.endDate"/>
            </div>
            <div class="col-6" [class.not-selected]="program.reportType != 'weeksOn'">
                <label><input type="radio" name="reportType" value="weeksOn" [(ngModel)]="program.reportType"/>&nbsp;Tracking per week on program</label>
                <div>
                    <p>
                        This report type aligns student week by week tracking based on the first time the student
                        was tracked in the time frame specified, and can be tuned based on the minimum number of
                        weeks students are tracked, to eliminate students that have just started the program.    
                    </p>
                </div>
                <label class="form-label">Start Date</label>
                <input type="date" class="form-control" [(ngModel)]="program.startDate"/>
                <label class="form-label">Minimum Weeks on program</label>
                <input class="form-control" type="number" [(ngModel)]="program.weeks" />
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <button class="btn btn-primary" (click)="runReport()">Run Report</button>
            </div>
        </div>
    </div>
</div>
<app-loading text="Generating Report" *ngIf="loading"></app-loading>
<div class="container" *ngIf="chart && !loading">
    <div class="row" >
        <div class="col-12">
            <button class="btn btn-primary" (click)="showReportSettings()">Report Settings</button>
        </div>
        <div class="col-12">
            <h1>Tracking weeks on program</h1>
            <canvas baseChart width="600" height="200"
                [data]="chart.data" 
                [options]="chart.options" 
                [type]="chart.type"
                [legend]="false"
                ></canvas>
        </div>
        <div class="col-12" style="overflow-x: scroll">
            <h1>Details</h1>
            <table class="table">
                <thead>
                    <th>Student</th>
                    <th *ngFor="let label of labels">{{label}}</th>
                </thead>
                <tbody>
                    <tr *ngFor="let row of tableData">
                        <td *ngFor="let item of row">{{item}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>