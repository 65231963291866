import { Component, OnInit } from '@angular/core';
import { 
  UserService, LicenseFeatures, ManageClass, ManageTemplates, StudentTemplate, StudentTemplateBehavior
} from '../../../lib';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {
  public studentBehaviors: StudentTemplateBehavior[];
  public studentTemplates: StudentTemplate[];
  public selected: StudentTemplate;
  public loading = true;
  public features: LicenseFeatures;
  private _manage: ManageClass;
  private _manageTemplates: ManageTemplates;

  constructor(
    private userService: UserService) { 

  }

  ngOnInit(): void {
    this.userService.user.subscribe(user => {
      if(!user) {
        return;
      }
      this._manage = user.management;
      this.load();
    })
  }

  async load() {
    const [license] = await Promise.all([
      this._manage.getLicense()
    ]);
    this.features = license.features;
    this._manageTemplates = await this._manage.getTemplates();
    this.studentTemplates = this._manageTemplates.students;
    
    if(this.studentTemplates.length == 0) {
      this.selected = this._manageTemplates.addNewTemplate();
    } else {
      this.selected = this.studentTemplates[0];
    }

    this.loading = false;
  }

  createNewStudent() {
    this.selected = this._manageTemplates.addNewTemplate();
    ;
  }
  setSelected(val: StudentTemplate) {
    this.selected = val;
  }
  studentRemoved() {
    if(this.studentTemplates.length == 0) {
      this.selected = this._manageTemplates.addNewTemplate();
    } else {
      this.selected = this.studentTemplates[0];
    }
  }
}
