<div class="view-area">
    <div class="container" *ngIf="user">
        <div class="row">
            <div class="col-12 text-center ">
                <h2>Welcome to</h2>
                <h1>mytaptrack&reg;</h1>
            </div>
            <div class="ml-auto mr-auto col-8 ">
                <p>
                    Let's set up your profile together. You will be able to add additional children and behaviors later.
                </p>
                <div class="form-group ">
                    <label>Your first name</label>
                    <input class="form-control " [(ngModel)]="user.details.firstName" />
                </div>
                <div class="form-group ">
                    <label>Your last name</label>
                    <input class="form-control " [(ngModel)]="user.details.lastName " />
                </div>
                <div class="form-group ">
                    <label>Display Name</label>
                    <input class="form-control " [(ngModel)]="user.details.name " />
                </div>
            </div>
            <div class="offset-2 col-4 form-group ">
                <label>Your State</label>
                <select class="form-control " [(ngModel)]="user.details.state">
                  <option value=" ">Select your state</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
              </select>
            </div>
            <div class="col-4 form-group ">
                <label>Zip Code</label>
                <input class="form-control " [(ngModel)]="user.details.zip " />
            </div>
        </div>
        <div class="row">
            <div class="offset-2 col-8 ">
                <p>
                    mytaptrack&reg; is targeted to be HIPAA and FERPA-compliant, meeting federal standards for the protection and privacy of children's health and education data. By clicking "Accept " you agree to mytaptrack&reg;'s
                    <a href=" ">Terms of Use</a>
                </p>
            </div>
            <div class="col-12 text-center">
                <a class="btn {{(userInfoComplete())? 'btn-primary': 'btn-secondary' }} " (click)="saveUserProfileInfo() ">Accept</a>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="loading "></app-loading>