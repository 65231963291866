import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbDate, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { 
  moment
} from '../../types';
import {
  DateTimeService, ReportDuration
} from '../../services';

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.css']
})
export class DateSelectionComponent implements OnInit {
  public months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public years = [];
  private now = moment();
  public showDownloadDropdown: boolean = false;
  public disabled: Observable<boolean> = new Observable();
  public ReportDuration = ReportDuration;
  
  @Output('selected') selected = new EventEmitter<{start: moment.Moment, end: moment.Moment, type: ReportDuration}>();
  
  public hoveredDate: NgbDate;
  public fromDate: NgbDate;
  public toDate: NgbDate;
  private _startDate: moment.Moment;
  private _endDate: moment.Moment;
  public rangeStart: Date;
  public rangeEnd: Date;
  private _calendarSetEnd: boolean;
  public model: NgbDate;
  @ViewChild("dp")
  public dp: NgbDatepicker;
  public selectionTypeVal: ReportDuration = ReportDuration.week;

  constructor(private calendar: NgbCalendar,
              private dateService: DateTimeService) {
    const now = new Date();
    for(let i = now.getFullYear() - 5; i <= now.getFullYear(); i++) {
      this.years.push(i);
    }
  }

  get selectionType() {
    return this.selectionTypeVal;
  }
  @Input()
  set selectionType(val: ReportDuration) {
    this.selectionTypeVal = val;
    if(!this.fromDate) {
      this.fromDate = new NgbDate(this.now.year(), this.now.month() + 1, this.now.date());
    }

    switch(val) {
      case ReportDuration.week:
        this.fromDate = this.calendar.getNext(this.fromDate, 'd', 15);
      case ReportDuration.month:
        this.onDateSelection(this.fromDate);
        this.notifyChange();
        break;
      case ReportDuration.year:
        this.startDate = this._startDate.clone().startOf('year');
        this.endDate = this._startDate.endOf('year');
        this.notifyChange();
        break;
      case ReportDuration.range:
        this.fromDate = null;
        this.toDate = null;
        this.rangeStart = this.startDate?.toDate();
        this.rangeEnd = this.endDate?.toDate();
    }
    this.notifyChange();
  }
  setSelectionType(val: ReportDuration) {
    this.selectionType = val;
    if(this.selectionType === ReportDuration.month) {
      this.selectMonth(this.months[this.startDate.month()]);
    }
  }

  get startDateString(): string {
    return this._startDate.format('MM/DD/yyyy');
  }
  set startDateString(val: string) {
    const date  = moment(val);
    if(date.isValid()) {
      this.startDate = date;
    } else {
      alert('Could not understand the start date');
    }
  }
  get startDate(): moment.Moment {
    if(!this.fromDate) {
      return this.now;
    } 
    return this._startDate;
  }
  @Input()
  set startDate(val: moment.Moment) {
    if(!val) {
      val = moment();
    }
    if(val.isSame(this._startDate, 'day')) {
      return;
    }
    const original = this._startDate;
    this.fromDate = new NgbDate(val.year(), val.month() + 1, val.date());

    this.model = this.fromDate;
    this._startDate = moment({ year: this.fromDate.year, month: this.fromDate.month - 1, day: this.fromDate.day });
    if(this.selectionType == ReportDuration.week) {
      this._calendarSetEnd = false;
      this.endDate = val.clone().add(1, 'week');
    }
    if(this.dp) {
      this.dp.navigateTo(this.fromDate);
    }

    // if(!original || !original.isSame(this._startDate)) {
    //   this.notifyChange();
    // }
  }

  get endDateString(): string {
    if(!this.toDate) {
      return '';
    }
    return moment(this._endDate).format('MM/DD/yyyy');
  }
  set endDateString(val: string) {
    const date  = moment(val);
    if(date.isValid()) {
      this._calendarSetEnd = false;
      this.endDate = date;
    } else {
      alert('Could not understand the start date');
    }
  }

  get endDate(): moment.Moment {
    return this._endDate
  }

  @Input()
  set endDate(val: moment.Moment) {
    const original = this._endDate;
    
    this.toDate = new NgbDate(val.year(), val.month() + 1, val.date());
    this._endDate = val.clone();
    if(this.dp) {
      this.dp.navigateTo(this.toDate);
    }
    // if(!original || !original.isSame(this._endDate)) {
    //   this.notifyChange();
    // }
  }

  public ngOnInit() {
    if(this.dp) {
      this.dp.navigateTo(this.fromDate);
    }
    if(!this._startDate) {
      this.startDate = moment();
    }
  }

  onDateSelection(ngbDate: NgbDate) {
    if(this.selectionType === ReportDuration.range) {
      if(this.fromDate && this.toDate) {
        this.fromDate = null;
        this.toDate = null;
      }

      if(!this.fromDate) {
        this.fromDate = ngbDate;
        this.startDate = moment({ year: this.fromDate.year, month: this.fromDate.month, day: this.fromDate.day });
      } else {
        this.toDate = ngbDate;
        this._calendarSetEnd = true;
        this.endDate = moment({ year: this.toDate.year, month: this.toDate.month, day: this.toDate.day });
      }
    } else {
      const isWeeks = this.selectionType === ReportDuration.week;
      const date = moment({ year: ngbDate.year, month: ngbDate.month - 1, day: ngbDate.day });
      if(isWeeks) {
        date.startOf('week');
        this.startDate = date.clone();
      } else {
        date.startOf('month');
        this.fromDate =  new NgbDate(date.year(), date.month() + 1, date.date());
      }
    }
    this.notifyChange();
  }

  notifyChange() {
    if(!this.toDate) {
      return;
    }
    let endDate = this.endDate;
    if(this.selectionType == ReportDuration.range) {
      endDate = endDate.clone().add(1, 'd');
    }
    this.selected.emit({  
      start: this.startDate,
      end: endDate,
      type: this.selectionType
    });
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return (date.after(this.fromDate) && date.before(this.toDate));
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || 
        this.isInside(date) || 
        this.isHovered(date) ||
        (this.selectionType === ReportDuration.range &&
          date.equals(this.toDate));
  }

  isOutsideMonth(date: NgbDate) {
    if(!this.fromDate || !date) {
      return false;
    }
    return this.fromDate.month !== date.month;
  }

  enableOk(): boolean {
    return (this.fromDate && this.toDate)? true : false;
  }
  onOk() {
    this.notifyChange();
    this.showDownloadDropdown = false;
  }

  incrementYear(yearsDelta: number) {
    this.fromDate.year += yearsDelta;
  }

  isYearSelected(year: number) {
    return this.startDate.year() == year;
  }

  applyRange() {
    this.startDate = moment(this.rangeStart);
    this.endDate = moment(this.rangeEnd);
    this.notifyChange();
    this.showDownloadDropdown = false;
  }

  selectYear(year: number) {
    this.startDate = moment(`1/1/${year}`);
    this.endDate = moment(`12/31/${year}`);
    this.notifyChange();
  }

  selectMonth(month: string) {
    const index = this.months.findIndex(x => x === month);
    let date = moment(`${index + 1}/1/${this.startDate.year()}`);
    this.startDate = date.clone();
    date = date.endOf('month');
    this.endDate = date;
    this.notifyChange();
  }

  isMonthSelected(month: string) {
    const index = this.months.findIndex(x => x === month);
    return this.startDate.month() === index;
  }

  yearAdd(count: number) {
    this.startDate = this.startDate.add(count, 'years');
  }

  setStartDateString(val: string) {
    if(this.startDateString != val) {
      this.startDateString = val;
    }
  }
}
