<div class="container-fluid" *ngIf="!loading">
    <div class="row">
        <div class="col-3">
            <div *ngIf="features.manageStudentTemplates">
                <h2>Templates:</h2>
                <ul>
                    <li *ngFor="let item of studentTemplates" [class.selected]="item == selected && item.name">
                        <a (click)="setSelected(item)">{{item.name}}</a>
                    </li>
                    <li>
                        <a (click)="createNewStudent()"><i class="fa fa-plus"></i> Add Student Template</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-9 main-area">
            <app-manage-student [template]="selected" [features]="features" (deleted)="studentRemoved()"></app-manage-student>
        </div>
    </div>
</div>
<app-loading *ngIf="loading"></app-loading>