<div *ngIf="studentObject && student">
    <div class="container-fluid col-6 mr-auto ml-auto">
        <div class="row">
            <div class="col-12" *ngIf="!studentObject.studentId">
                <h1>Create new student</h1>
            </div>
            <div class="col-12" *ngIf="studentObject.studentId">
                <h1>Update {{student.firstName}} {{student.lastName}}</h1>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12">
                <label>First Name</label>
                <input id="create-student-first-name" class="form-control" [(ngModel)]="student.firstName" />
                <div class="validation-error-text" *ngIf="firstNameError">{{firstNameError}}</div>
            </div>
            <div class="form-group col-12">
                <label>Last Name</label>
                <input id="create-student-last-name" class="form-control" [(ngModel)]="student.lastName" />
                <div class="validation-error-text" *ngIf="lastNameError">{{lastNameError}}</div>
            </div>
            <div class="form-group col-12">
                <label>Nickname</label>
                <input id="create-student-nick-name" class="form-control" [(ngModel)]="student.nickname" />
                <div class="validation-error-text" *ngIf="nickNameError">{{nickNameError}}</div>
            </div>
            <div class="form-group col-12">
                <label>Tags for Student</label>
                <app-mtt-tags [(tagField)]="studentObject.tags"></app-mtt-tags>
            </div>
            <div class="form-group col-12" *ngIf="!studentObject.studentId">
                <label>Your Role</label>
                <select  id="create-student-your-role" class="form-control" [(ngModel)]="role">
                  <option></option>
                  <option *ngFor="let item of roles">{{item}}</option>
                </select>
                <div class="validation-error-text" *ngIf="roleError">{{roleError}}</div>
            </div>
            <div *ngIf="userStudents" class="form-group col-12">
                <label><input  id="create-student-copy-student-check" type="checkbox" [(ngModel)]="copyOtherBehaviors"/> Copy behaviors from another student</label>
                <select id="create-student-copy-student-select" class="form-control" *ngIf="copyOtherBehaviors" [(ngModel)]="copyOtherStudent">
                    <option value=""></option>
                    <option *ngFor="let userStudent of userStudents" [value]="userStudent.studentId">{{userStudent.firstName}} {{userStudent.lastName}}</option>
                </select>
            </div>
            <div class="col-12" *ngIf="studentObject && !studentObject.studentId">
                <label>License:</label>
                <div class="btn-group btn-group-toggle">
                    <button id="create-student-license-annual" [disabled]="!hasSingleLicense()" class="btn" [class.btn-primary]="hasSingleLicense()" [class.btn-secondary]="!hasSingleLicense()"  (click)="licenseType = 'Single'">
                        <i *ngIf="licenseType == 'Single'" class="fa fa-check" aria-hidden="true"></i> Annual Dedicated License
                    </button>
                    <button id="create-student-license-flexible" [disabled]="!hasMultiLicense()" class="btn" [class.btn-primary]="hasMultiLicense()" [class.btn-secondary]="!hasMultiLicense()" (click)="licenseType = 'Multi'">
                        <i *ngIf="licenseType == 'Multi'" class="fa fa-check" aria-hidden="true"></i> Flexible Student License
                    </button>
                    <button id="create-student-license-no" class="btn btn-primary" (click)="licenseType = 'No License'">
                        <i *ngIf="licenseType == 'No License'" class="fa fa-check" aria-hidden="true"></i> No License
                    </button>
                </div>
            </div>
            <div class="col-12" *ngIf="allowConvertLicense">
                <label>License:</label>
                <div class="btn-group btn-group-toggle">
                    <button id="create-student-license2-annual" [disabled]="!hasSingleLicense()" class="btn" [class.btn-primary]="hasSingleLicense()" [class.btn-secondary]="!hasSingleLicense()"  (click)="licenseType = 'Single'">
                        <i *ngIf="licenseType == 'Single'" class="fa fa-check" aria-hidden="true"></i> Annual Dedicated License
                    </button>
                    <button id="create-student-license2-flexible" [disabled]="!hasMultiLicense()" class="btn" [class.btn-primary]="hasMultiLicense()" [class.btn-secondary]="!hasMultiLicense()" (click)="licenseType = 'Multi'">
                        <i *ngIf="licenseType == 'Multi'" class="fa fa-check" aria-hidden="true"></i> Flexible Student License
                    </button>
                    <button id="create-student-license2-no" class="btn btn-primary" (click)="licenseType = 'No License'" [disabled]="licenseType == 'Other'">
                        <i *ngIf="licenseType == 'No License'" class="fa fa-check" aria-hidden="true"></i> No License
                    </button>
                    <button id="create-student-license2-other" class="btn btn-primary" (click)="licenseType = 'Other'" disabled>
                        <i *ngIf="licenseType == 'Other'" class="fa fa-check" aria-hidden="true"></i> Other License
                    </button>
                </div>
            </div>
            <div class="col-12 container">
                <div class="row">
                    <div class="col-6 text-center">
                        <a id="create-student-cancel" class="btn btn-secondary" href="/" *ngIf="!studentObject.studentId">Cancel</a>
                        <a id="create-student-cancel2" class="btn btn-secondary" [routerLink]="['/student/settings']" [queryParams]="{studentId:studentObject.studentId}" *ngIf="studentObject.studentId">Cancel</a>
                    </div>
                    <div class="col-6  text-center">
                        <button id="create-student-create-update" class="btn" [class.btn-success]="allValid()" [class.btn-light]="!allValid()" [disabled]="!allValid()" (click)="create()">
                            <span *ngIf="studentObject.studentId">Update</span>
                            <span *ngIf="!studentObject.studentId">Create</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="loading"></app-loading>