
export const environment = {
  production: true,
  UserPoolId: 'us-west-2_Gw7zVNGLF',
  IdentityPoolId: 'us-west-2:0eb72496-658a-4e06-ba49-fbed1ad319ca',
  IdentityAppId: '5v8dukpcf1dk916ofj8m0jnkvf',
  Region: 'us-west-2',
  IdentityAppDomain: 'portal.mytaptrack.com',
  apiRoot: 'https://api.mytaptrack.com/api/',
  apiRootCloudfront: '/api/',
  graphqlEndpoint: 'https://z3q5kfmyyva43jlij336fcct44.appsync-api.us-west-2.amazonaws.com/graphql',
  forgotPasswordUrl: `https://mytaptrack.auth.us-west-2.amazoncognito.com/forgotPassword?response_type=token&client_id=3noufbek5ku571s5mdndi0i9jf&redirect_uri=https://mytaptrack-test.com/dashboard&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin+email`,
  signUpUrl: '',
  googleLogin: 'https://mytaptrack.auth.us-west-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://portal.mytaptrack.com/dashboard&response_type=TOKEN&client_id=61f7lakgd2mchf12dko1l0mabs&state=STATE&scope=openid profile aws.cognito.signin.user.admin email',
  cognitoClientId: '5v8dukpcf1dk916ofj8m0jnkvf',
  cognitoCallbackUrl: 'https://portal.mytaptrack.com/dashboard',
  cognitoLoginDomain: 'mytaptrack.auth.us-west-2.amazoncognito.com',
  googleAnalyticsId: 'UA-134162051-2',
  googleClientId: '1097847060104-oq8nrs4skho6rn9vpp7j5aos122mft19.apps.googleusercontent.com',
  qrCode: true,
  responseTracking: false,
  responseTrackingUsers: ['parent@mytaptrack.com', 'tbennett@huffman.k12.tx.us','demo@mytaptrack.com'],
  managementUsers: ['parent@mytaptrack.com', 'tbennett@huffman.k12.tx.us'],
  reporting: true,
  routes: {
    manage: 'https://portal.mytaptrack.com/manage',
    behavior: 'https://portal.mytaptrack.com/',
    service: 'https://portal.mytaptrack.com/services',
  }
};
