<div class="dropdown date-dropdown d-print-none" #dropdown="bs-dropdown" [insideClick]="true" dropdown autoClose="true">
    <a id="datepicker-dropdown-toggle" dropdownToggle class="dropdown-toggle profile-button" aria-controls="datepicker-dropdown-menu">
        <i class="fa fa-calendar" aria-hidden="true"></i>
    </a>
    <div id="datepicker-dropdown-menu" *dropdownMenu class="dropdown-menu dropdown-menu-right"  role="menu" aria-labelledby="datepicker-dropdown-toggle">
        <app-date-selection (selected)="selectedChanged($event)" 
            [selectionType]="selectionType"
            [startDate]="startDateDate"
            ></app-date-selection>
    </div>
</div>