<h1>ABC Manager</h1>
<app-loading *ngIf="loading"></app-loading>
<div class="container-fluid" *ngIf="!loading">
    <div class="row">
        <div class="col-3">
            <div *ngFor="let item of collections" [class.selected]="selected && item == selected">
                <a (click)="selected = item">{{item.name}}</a>
            </div>
            <div [class.selected]="noneSelected"><a (click)="addCollection()"><i class="fa fa-plus"></i> Add ABC Setting</a></div>
        </div>
        <div class="col-9 select-details">
            <app-abc-collection [(item)]="selected" [collections]="collections"></app-abc-collection>
        </div>
    </div>
</div>