import { Auth } from 'aws-amplify';
import { MytaptrackEnvironment } from './environment';

export function amplifyConfig(environment: MytaptrackEnvironment) {
    return {
        aws_cognito_region: environment.Region,
        aws_user_pools_id: environment.UserPoolId,
        aws_user_pools_web_client_id: environment.cognitoClientId,
        aws_cognito_identity_pool_id: environment.IdentityPoolId,
        aws_mandatory_sign_in: 'enable',
        oauth: {
            domain: environment.cognitoLoginDomain,
            scope: [
                'phone',
                'email',
                'profile',
                'openid',
                'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: `https://${environment.IdentityAppDomain}`,
            redirectSignOut: `https://${environment.IdentityAppDomain}`,
            clientId: environment.cognitoClientId,
            responseType: 'code'
        },
        Auth: {
            identityPoolId: environment.IdentityPoolId,
            region: environment.Region,
            userPoolId: environment.UserPoolId,
            userPoolWebClientId: environment.cognitoClientId,
        },
        API: {
            endpoints: [
                {
                    name: 'api',
                    endpoint: environment.apiRoot,
                    custom_header: async () => {
                        const token = (await Auth.currentSession())?.getIdToken()?.getJwtToken();
                        if(!token) {
                            return {};
                        }
                        return { Authorization: `Bearer ${token}` };
                    }
                }
            ]
        },
        aws_appsync_graphqlEndpoint: environment.graphqlEndpoint,
        aws_appsync_region: environment.Region,
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
    };
}