<div class="row">
    <div class="col-12">
        <app-loading *ngIf="loading" [absolute]="true" text="Loading"></app-loading>
    </div>
</div>
<div class="row" *ngIf="team">
    <div class="col-12">
        <h2>Team Members</h2>
    </div>
</div>
<div class="row" *ngIf="team">
    <div class="col-4">
        <ul>
            <li *ngFor="let item of team; index as i" [class.selected]="selected && item.userId == selected.userId">
                <a [id]="'student-settings-team-' + i" (click)="setSelected(item)">{{item.details.name}}</a>
            </li>
            <li [class.selected]="!selected || !selected.userId">
                <a id="student-settings-team-new" title="Assign new device" (click)="create()"><i class="fa fa-plus"></i> Add New Team Member</a>
            </li>
        </ul>
    </div>
    <div class="col-8 details" style="position:relative">
        <app-loading *ngIf="loading" [absolute]="true" text="Saving"></app-loading>
        <div class="form-group" *ngIf="!isNew">
            <label>Name</label>
            <div>
                {{name}}
            </div>
        </div>
        <div class="form-group" *ngIf="isNew" [class.form-error]="emailError">
            <label>Email</label>
            <input id="student-settings-team-email" type="text" class="form-control" [(ngModel)]="selected.details.email" />
            <div class="hint">
                Invitation email will be sent to this email address with steps to create a mytaptrack account and accept this student. Please be sure the new Team member creates their account using the same email address as the one you add here.
            </div>
        </div>
        <div>
            <h4>Permissions:</h4>
        </div>

        <div class="form-group">
            <table class="table">
                <thead>
                    <th>Permission</th>
                    <th class="text-center">Edit Access</th>
                    <th class="text-center">Read Access</th>
                    <th class="text-center">No Access</th>
                </thead>
                <tbody>
                    <tr>
                        <td>Data</td>
                        <td class="text-center"><input id="student-settings-team-data-admin" type="radio" name="data-perm" [checked]="selected.restrictions.data == 'Admin'" (click)="selected.restrictions.data = 'Admin'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-data-read" type="radio" name="data-perm" [checked]="selected.restrictions.data == 'Read'" (click)="selected.restrictions.data = 'Read'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-data-no" type="radio" name="data-perm" [checked]="selected.restrictions.data == 'No Access'" (click)="selected.restrictions.data = 'No Access'" [disabled]="!administrator"/></td>
                    </tr>
                    <tr>
                        <td>Documents</td>
                        <td class="text-center"><input id="student-settings-team-data-admin" type="radio" name="documents-perm" [checked]="selected.restrictions.documents == 'Admin'" (click)="selected.restrictions.documents = 'Admin'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-data-read" type="radio" name="documents-perm" [checked]="selected.restrictions.documents == 'Read'" (click)="selected.restrictions.documents = 'Read'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-data-no" type="radio" name="documents-perm" [checked]="selected.restrictions.documents == 'No Access'" (click)="selected.restrictions.documents = 'No Access'" [disabled]="!administrator"/></td>
                    </tr>
                    <tr>
                        <td>Snapshot</td>
                        <td class="text-center"><input id="student-settings-team-snapshot-admin" type="radio" name="reports-perm" [checked]="selected.restrictions.reports == 'Admin'" (click)="selected.restrictions.reports = 'Admin'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-snapshot-read" type="radio" name="reports-perm" [checked]="selected.restrictions.reports == 'Read'" (click)="selected.restrictions.reports = 'Read'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-snapshot-no" type="radio" name="reports-perm" [checked]="selected.restrictions.reports == 'No Access'" (click)="selected.restrictions.reports = 'No Access'" [disabled]="!administrator"/></td>
                    </tr>
                    <tr *ngIf="selected.restrictions.reports == 'Admin'">
                        <td>Snapshot Target Override</td>
                        <td class="text-center"><input id="student-settings-team-snapshot-overwrite" type="checkbox" [(ngModel)]="selected.restrictions.reportsOverride" [disabled]="!administrator"/> </td>
                    </tr>
                    <tr>
                        <td>Schedules</td>
                        <td class="text-center"><input id="student-settings-team-schedules-admin" type="radio" name="schedule-perm" [checked]="selected.restrictions.schedules == 'Admin'" (click)="selected.restrictions.schedules = 'Admin'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-schedules-read" type="radio" name="schedule-perm" [checked]="selected.restrictions.schedules == 'Read'" (click)="selected.restrictions.schedules = 'Read'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-schedules-no" type="radio" name="schedule-perm" [checked]="selected.restrictions.schedules == 'No Access'" (click)="selected.restrictions.schedules = 'No Access'" [disabled]="!administrator"/></td>
                    </tr>
                    <tr>
                        <td>Devices</td>
                        <td class="text-center"><input id="student-settings-team-devices-admin" type="radio" name="devices-perm" [checked]="selected.restrictions.devices == 'Admin'" (click)="selected.restrictions.devices = 'Admin'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-devices-read" type="radio" name="devices-perm" [checked]="selected.restrictions.devices == 'Read'" (click)="selected.restrictions.devices = 'Read'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-devices-no" type="radio" name="devices-perm" [checked]="selected.restrictions.devices == 'No Access'" (click)="selected.restrictions.devices = 'No Access'" [disabled]="!administrator"/></td>
                    </tr>
                    <tr>
                        <td>Notes</td>
                        <td class="text-center"><input id="student-settings-team-notes-admin" type="radio" name="comments-perm" [checked]="selected.restrictions.comments == 'Admin'" (click)="selected.restrictions.comments = 'Admin'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-notes-read" type="radio" name="comments-perm" [checked]="selected.restrictions.comments == 'Read'" (click)="selected.restrictions.comments = 'Read'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-notes-no" type="radio" name="comments-perm" [checked]="selected.restrictions.comments == 'No Access'" (click)="selected.restrictions.comments = 'No Access'" [disabled]="!administrator"/></td>
                    </tr>
                    <tr>
                        <td>Behaviors</td>
                        <td class="text-center"><input id="student-settings-team-beh-admin" type="radio" name="behavior-perm" [checked]="selected.restrictions.behavior == 'Admin'" (click)="selected.restrictions.behavior = 'Admin'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-beh-read" type="radio" name="behavior-perm" [checked]="selected.restrictions.behavior == 'Read'" (click)="selected.restrictions.behavior = 'Read'" [disabled]="!administrator"/></td>
                    </tr>
                    <tr *ngIf="features.abc">
                        <td>ABC <i class="fa fa-info-circle" title="Ability to view and configure ABC options"></i></td>
                        <td class="text-center"><input id="student-settings-team-abc-admin" type="radio" name="abc-perm" [checked]="selected.restrictions.abc == 'Admin'" (click)="selected.restrictions.abc = 'Admin'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-abc-read" type="radio" name="abc-perm" [checked]="selected.restrictions.abc == 'Read'" (click)="selected.restrictions.abc = 'Read'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-abc-no" type="radio" name="abc-perm" [checked]="selected.restrictions.abc == 'No Access'" (click)="selected.restrictions.abc = 'No Access'" [disabled]="!administrator"/></td>
                    </tr>
                    <tr>
                        <td>Support Changes</td>
                        <td class="text-center"><input id="student-settings-team-sup-admin" type="radio" name="support-perm" [checked]="selected.restrictions.milestones == 'Admin'" (click)="selected.restrictions.milestones = 'Admin'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-sup-read" type="radio" name="support-perm" [checked]="selected.restrictions.milestones == 'Read'" (click)="selected.restrictions.milestones = 'Read'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-sup-no" type="radio" name="support-perm" [checked]="selected.restrictions.milestones == 'No Access'" (click)="selected.restrictions.milestones = 'No Access'" [disabled]="!administrator"/></td>
                    </tr>
                    <tr>
                        <td>Team</td>
                        <td class="text-center"><input id="student-settings-team-team-admin" type="radio" name="team-perm" [checked]="selected.restrictions.team == 'Admin'" (click)="selected.restrictions.team = 'Admin'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-team-read" type="radio" name="team-perm" [checked]="selected.restrictions.team == 'Read'" (click)="selected.restrictions.team = 'Read'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-team-no" type="radio" name="team-perm" [checked]="selected.restrictions.team == 'No Access'" (click)="selected.restrictions.team = 'No Access'" [disabled]="!administrator"/></td>
                    </tr>
                    <tr>
                        <td>Notifications</td>
                        <td class="text-center"><input id="student-settings-team-notification-admin" type="radio" name="notification-perm" [checked]="selected.restrictions.notifications == 'Admin'" (click)="selected.restrictions.notifications = 'Admin'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-notification-read" type="radio" name="notification-perm" [checked]="selected.restrictions.notifications == 'Read'" (click)="selected.restrictions.notifications = 'Read'" [disabled]="!administrator"/></td>
                        <td class="text-center"><input id="student-settings-team-notification-no" type="radio" name="notification-perm" [checked]="selected.restrictions.notifications == 'No Access'" (click)="selected.restrictions.notifications = 'No Access'" [disabled]="!administrator"/></td>
                    </tr>
                    <tr>
                        <td>Transfer License</td>
                        <td class="text-center"><input id="student-settings-team-lic-admin" type="radio" name="trans-license-perm" [checked]="selected.restrictions.transferLicense" (click)="setTransferLicense(true)" [disabled]="!administrator"/></td>
                        <td class="text-center"></td>
                        <td class="text-center"><input  id="student-settings-team-lic-no" type="radio" name="trans-license-perm" [checked]="!selected.restrictions.transferLicense" (click)="setTransferLicense(false)" [disabled]="!administrator"/></td>
                    </tr>
                </tbody>
            </table>
            <h4>Behaviors</h4>
            <input type="radio" [checked]="isFullAccess()" (click)="setAccessFull(true)" [disabled]="!administrator"/><label>&nbsp;All Behaviors</label>
            <input class="radio-second" [checked]="accessType == 'limited'" value="limited" type="radio" (click)="setAccessFull(false)" [disabled]="!administrator"/><label>&nbsp;Limited Behaviors</label>
            <div *ngIf="!hasFullAccess()">
                <div *ngIf="administrator">
                    <button class="btn btn-primary" (click)="selectAllBehaviors()">Select All</button>
                    <button class="btn btn-primary" (click)="clearAllBehaviors()">Clear All</button>
                </div>
                <h5>Active Behaviors:</h5>
                <div *ngFor="let b of activeBehaviors">
                    <input id="behavior_{{b.id}}" type="checkbox" [ngModel]="hasPermission(b)" (click)="togglePermission(b)" [disabled]="!administrator">
                    <label for="behavior_{{b.id}}">&nbsp;{{b.name}} <i class="fa fa-clock-o" *ngIf="b.isDuration"></i></label>
                </div>
                <div>
                    <h5>Archived Behaviors:</h5>
                </div>
                <div class="archived" *ngFor="let b of archivedBehaviors">
                    <input id="behavior_{{b.id}}" type="checkbox" [ngModel]="hasPermission(b)" (click)="togglePermission(b)" [disabled]="!administrator">
                    <label for="behavior_{{b.id}}">&nbsp;{{b.name}} <i class="fa fa-clock-o" *ngIf="b.isDuration"></i></label>
                </div>
            </div>
        </div>
        <div class="text-center" *ngIf="administrator">
            <div class="error-text" *ngIf="emailError">
                The email address supplied is either invalid or not formatted correctly.
            </div>
            <div class="error-text" *ngIf="roleError">
                A role was not specified for the new team member.
            </div>
            <button class="btn btn-primary" (click)="save()">Save</button>
            <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
            <button class="btn btn-danger" (click)="remove()" *ngIf="!isNew">Remove</button>
        </div>
    </div>
</div>