<div class="note">
    <div>
        <span class="note-source">{{note.source?.name ?? ""}}</span>
        <span class="note-date">{{note.date}}</span>
        <i *ngIf="saving" class="fa fa-spin fa-circle-o-notch"></i>
        <span class="note-updated" *ngIf="hasSaved && !saving">&nbsp;Updated</span>
    </div>
    <div class="note-body">
        <textarea #noteText [(ngModel)]="notes" (load)="onNoteChange()"></textarea>
    </div>
    <div class="controls">
        <!-- <a class="edit"><mat-icon>edit</mat-icon> Edit</a> -->
        <a class="error" (click)="delete()"><i class="fa fa-trash" aria-hidden="true"></i> Delete</a>
    </div>
</div>
