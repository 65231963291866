<div class="row">
    <div class="col-12">
        <h2>Notifications</h2>
    </div>
</div>
<div class="row">
    <div class="col-4">
        <ul>
            <li *ngFor="let item of subscriptions; index as i" [class.selected]="selected && item == selected">
                <a [id]="'student-setting-subscriptions-' + i" (click)="setSelected(item)">{{item.name}}</a>
            </li>
            <li>
                <a id="student-setting-notifications-new" *ngIf="administrator" title="Add notification" (click)="createNotification()"><i class="fa fa-plus"></i> Add Notification</a>
            </li>
        </ul>
    </div>
    <div class="col-8 details" style="position:relative" *ngIf="selected">
        <div>
            <div class="form-group">
                <label>Name</label>
                <input id="notification-name" class="form-control"  [(ngModel)]="selected.name" [disabled]="readonly" />
            </div>
            <div class="form-group">
                <div class="accordion" id="settingsAccordion">
                    <div class="card">
                        <div class="card-header" id="headingSubscriberSettings">
                            <h2 class="mb-0">
                                <button id="student-setting-notifications-behavior" class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#subscriberSettings" aria-expanded="true" aria-controls="subscriberSettings">
                                    Who is notified
                                </button>
                            </h2>
                        </div>
                        <div id="subscriberSettings" class="collapse show" aria-labelledby="headingSubscriberSettings" data-parent="#settingsAccordion">
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Emails</label>
                                    <app-mtt-tags [(tagField)]="selected.emails" typeText="email" [edit]="!readonly"></app-mtt-tags>
                                </div>
                                <div class="form-group">
                                    <label>Text Messages</label>
                                    <app-mtt-tags [(tagField)]="selected.mobiles" typeText="phone number" format="phone" [edit]="!readonly"></app-mtt-tags>
                                </div>

                                <div class="form-group" *ngIf="devices">
                                    <label>Apps</label>
                                    <div *ngFor="let app of devices.apps">
                                        <label><input type="checkbox" [checked]="isSet(app)" (click)="toggle(app)" [disabled]="readonly"/> {{app.deviceName}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Website Alerts</label>
                                    <div *ngFor="let member of teamMembers">
                                        <label><input type="checkbox" [checked]="isSet(member)" (click)="toggle(member)" [disabled]="readonly"/> {{member.details.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingMessages">
                            <h2 class="mb-0">
                                <button id="student-setting-notifications-behavior" 
                                        class="btn btn-link btn-block text-left" 
                                        type="button" 
                                        data-toggle="collapse" 
                                        data-target="#messages" 
                                        aria-expanded="true" 
                                        aria-controls="messages">
                                    What does the notification say
                                </button>
                            </h2>
                        </div>
                        <div id="messages" class="collapse" aria-labelledby="headingMessages" data-parent="#settingsAccordion">
                            <div class="card-body">
                                <div *ngIf="!readonly">
                                    <a class="link-item" (click)="showPlaceholders = !showPlaceholders">
                                        {{showPlaceholders? 'Hide' : 'Show'}} Placeholders
                                    </a>
                                    <table class="table" *ngIf="showPlaceholders">
                                        <thead>
                                            <th>Placeholder</th>
                                            <th>Description</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>&#123;Behavior&#125;</td>
                                                <td>The behavior's name that was tracked</td>
                                            </tr>
                                            <tr>
                                                <td>&#123;FirstName&#125;</td>
                                                <td>The student's first name</td>
                                            </tr>
                                            <tr>
                                                <td>&#123;LastName&#125;</td>
                                                <td>The student's last name</td>
                                            </tr>
                                            <tr>
                                                <td>&#123;Nickname&#125;</td>
                                                <td>The nickname for the student</td>
                                            </tr>
                                            <tr>
                                                <td>&#123;WhoTracked&#125;</td>
                                                <td>The name of what tracked the behavior: the device, app or individual on the website.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <label>Default Message (email, sms, app)</label>
                                <textarea class="form-control" [(ngModel)]="selected.messages.default" [readonly]="readonly"></textarea>
                                <div class="hint">
                                    Messages can be more explicit, but the responsibility of keeping information
                                    private with push notifications, email and text messaging can not be guaranteed.

                                    Leverage the following to provide a adaptive template for messages.
                                </div>
                                <div>
                                    <a class="link-item" *ngIf="!readonly && !selected.messages.email && !showCustomEmail" (click)="showCustomEmail = true">customize email</a>
                                    <div class="form-group" *ngIf="selected.messages.email || showCustomEmail">
                                        <label>Custom email message:</label>
                                        <textarea class="form-control" [(ngModel)]="selected.messages.email" [readonly]="readonly"></textarea>
                                    </div>
                                </div>
                                <div>
                                    <a class="link-item" *ngIf="!readonly && !selected.messages.mobile && !showCustomSms" (click)="showCustomSms = true">customize sms</a>
                                    <div class="form-group" *ngIf="selected.messages.mobile || showCustomSms">
                                        <label>Custom sms message:</label>
                                        <textarea class="form-control" [(ngModel)]="selected.messages.mobile" [readonly]="readonly"></textarea>
                                    </div>
                                </div>
                                <div>
                                    <a class="link-item" *ngIf="!readonly && !selected.messages.app && !showCustomApp" (click)="showCustomApp = true">customize app notification message</a>
                                    <div class="form-group" *ngIf="selected.messages.app || showCustomApp">
                                        <label>Customize app notification message:</label>
                                        <textarea class="form-control" [(ngModel)]="selected.messages.app"  [readonly]="readonly"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingBehaviorSettings">
                            <h2 class="mb-0">
                                <button id="student-setting-notifications-behavior" class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#behaviorSettings" aria-expanded="true" aria-controls="behaviorSettings">
                                    What triggers the notification
                                </button>
                            </h2>
                        </div>
                        <div id="behaviorSettings" class="collapse" aria-labelledby="headingBehaviorSettings" data-parent="#settingsAccordion">
                            <div class="card-body">
                                <div>
                                    <h3>Notify on behaviors:</h3>
                                </div>
                                <div *ngFor="let behavior of student.trackables.activeBehaviors">
                                    <label>
                                        <input [id]="'student-setting-notifications-behavior-' + behavior.id" type="checkbox" [checked]="isSet(behavior)" (click)="toggle(behavior)" [disabled]="readonly"/> {{behavior.name}}
                                    </label>
                                </div>
                                <div *ngIf="student.trackables.activeResponses.length > 0">
                                    <h3>Notify on responses:</h3>
                                </div>
                                <div *ngFor="let behavior of student.trackables.activeResponses">
                                    <label>
                                        <input [id]="'student-setting-notifications-response-' + behavior.id" type="checkbox" [checked]="isSet(behavior, true)" (click)="toggle(behavior, true)" [disabled]="readonly"/> Response: {{behavior.name}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="features.response">
                        <div class="card-header" id="headingResponseSettings">
                            <h2 class="mb-0">
                                <button id="student-setting-notifications-response" class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#responseSettings" aria-expanded="true" aria-controls="responseSettings">
                                    Notify until
                                </button>
                            </h2>
                        </div>
                        <div id="responseSettings" class="collapse" aria-labelledby="headingResponseSettings" data-parent="#settingsAccordion">
                            <div class="card-body">
                                <div class="form-group">
                                    <div>
                                        <label>Notify until one of the selected responses is tracked?</label>
                                    </div>
                                    <div>
                                        <label><input name="notifyUntil" type="radio" [checked]="selected.notifyUntilResponse" (click)="toggleNotifyUntil(true)" [disabled]="readonly"/> Yes</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <label><input name="notifyUntil" type="radio" [checked]="!selected.notifyUntilResponse" (click)="toggleNotifyUntil(false)" [disabled]="readonly"/> No</label>
                                    </div>
                                </div>
                                <div *ngIf="selected.notifyUntilResponse">
                                    <label>Select responses which will stop notifications</label>
                                    <div *ngFor="let response of student.trackables.activeResponses">
                                        <label>
                                            <input [id]="'student-setting-notifications-response-' + response.id" type="checkbox" [checked]="isSetResponse(response)" (click)="toggleResponse(response)" [disabled]="readonly"/> {{response.name}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!readonly">
                <button class="btn btn-primary" (click)="save()"><i class="fa fa-floppy-o"></i> Save</button>
                <button class="btn btn-secondary" (click)="selected.cancel()"><i class="fa fa-times"></i> Cancel</button>
                <button class="btn btn-danger" (click)="delete()"><i class="fa fa-trash"></i> Delete</button>
            </div>
        </div>
        <app-loading *ngIf="saving" [absolute]="true" text="Saving"></app-loading>
    </div>
    <div class="col-8 details" *ngIf="!selected">
        <app-loading *ngIf="loading" [absolute]="true" [text]="loadingText"></app-loading>
    </div>
</div>
