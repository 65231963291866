import { Component, OnInit, Input } from '@angular/core';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { StudentClass, AccessLevel, MilestoneClass, moment } from '../../../../lib';

@Component({
  selector: 'app-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['./milestones.component.css']
})
export class MilestonesComponent implements OnInit {
  public student: StudentClass;
  public get milestones() { return this.student.milestones; };
  public selected: MilestoneClass;
  public administrator: boolean;
  public selectedDate: NgbDate;
  public loading: boolean;

  constructor(private calendar: NgbCalendar) { }

  @Input('student')
  public set setStudent(val: StudentClass) {
    this.student = val;
    if(!val) {
      return;
    }
    const restrictions = val.restrictions;
    this.administrator = restrictions && restrictions.milestones === AccessLevel.admin;

    val.milestones.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });

    this.setSelected(val.milestones.length > 0? val.milestones[0] : null);
  }

  ngOnInit(): void {
  }

  private async setLoading(val: boolean) {
    this.loading = val;
  }

  public get selectedDateHandler() {
    return this.selectedDate;
  }
  public set selectedDateHandler(date: NgbDate) {
    this.selectedDate = date;
    this.selected.date = `${date.month}/${date.day}/${date.year}`;
    
    const element = window.document.getElementById('dateField');
    element.focus();
  }
  public get inputDateHandler(): string {
    return (this.selected)? this.selected.date : '';
  }
  public set inputDateHandler(val: string) {
    const date = val.indexOf('-') >= 0? moment(val, 'yyyy-MM-DD') : moment(val, 'MM/DD/yyyy');
    this.selectedDate = this.calendar.getToday();
    this.selectedDate.year = date.year();
    this.selectedDate.month = date.month() + 1;
    this.selectedDate.day = date.date();
    this.selected.date = date.format('MM/DD/yyyy');
  }

  setSelected(val: MilestoneClass) {
    const date = new Date();
    this.selected = val ?? this.student.createMilestone({
      title: '',
      description: '',
      date: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
    });

    this.selectedDate = this.calendar.getToday();
    this.inputDateHandler = this.selected.date;
  }
  create() {
    this.setSelected(null);
  }

  isNew(): boolean {
    return this.selected.isNew;
  }

  async save() {
    this.setLoading(true);
    try {
      await this.selected.save();
    } catch(err) {
      console.log(err);
      alert(err.message);
    }
    this.setLoading(false);
  }

  cancel() {
    this.selected.cancel();
  }

  async remove() {
    if(!confirm(`Do you mean to delete ${this.selected.title}`)) {
      return;
    }

    this.setLoading(true);
    try {
      await this.selected.remove();
      if(this.student.milestones.length > 0) {
        this.setSelected(this.student.milestones[0]);
      } else {
        this.setSelected(null);
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    } finally {
      this.setLoading(false);
    }
  }
}
